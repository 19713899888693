import { PinturaEditor } from "@pqina/react-pintura";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, useTheme, Button } from "@mui/material";
import { getEditorDefaults } from "@pqina/pintura";
import busur from "../../images/busur.png";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { UserAuth } from "../../context/AuthContext";
import Alert from "@mui/material/Alert";
import "@pqina/pintura/pintura.css";
import FilerobotImageEditor, {
  TABS,
  TOOLS,
} from "react-filerobot-image-editor";

function Pintura() {
  const { state } = useLocation();
  const { imageUrl, data, pasien } = state || {};
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isImgEditorShown, setIsImgEditorShown] = useState(false);
  const { user } = UserAuth();

  const openImgEditor = () => {
    setIsImgEditorShown(true);
  };

  const closeImgEditor = () => {
    setIsImgEditorShown(false);
  };

  
  const navigate = useNavigate();
  const handleClick = async (img) => {
    console.log(img)
    try {
      const base64Image = img.imageBase64.split(',')[1];


      const byteCharacters = atob(base64Image);
      const byteArrays = [];
  
      for (let i = 0; i < byteCharacters.length; i++) {
        byteArrays.push(byteCharacters.charCodeAt(i));
      }
  
      const blob = new Blob([new Uint8Array(byteArrays)], { type: img.mimeType });
  

      const newimg = new File([blob], img.fullName, { type: img.mimeType });

      
        const formData = new FormData();
        formData.append("file", newimg);
        formData.append("progress", data.progress);
        formData.append("photoCategory", data.photoCategory);
        formData.append("idDoctor", "7ed91ec28dcb4f61ad6d4a2a6078cf15");
        formData.append("idPatient", pasien.userId);
  
        const response = await fetch(
          "https://ashokanew-bjh4uafqtq-uc.a.run.app/stage",
          {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
              Admin: "true",
            },
          }
        );
        console.log(response);
  
        if (response.ok) {
          navigate("/pasien/" + pasien.userId);
        } else {
          throw new Error("Request failed");
        }
       
      }
      catch (error) {
        console.error(error);
        setSuccess(false);
        setError(true);
        // Handle the error
      }
  };
  const [inlineResult, setInlineResult] = useState();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box m="20px">
      <Header title="Image Editor" subtitle="" />
      <Box
        sx={{
          mt: 2,
          height: 600,
        }}
      >
        {success && (
          <Alert sx={{ mb: 1 }} severity="success">
            Upload Success!
          </Alert>
        )}
        {error && (
          <Alert sx={{ mb: 1 }} severity="error">
            Upload Gagal!
          </Alert>
        )}
        {/* <PinturaEditor
                {...getEditorDefaults()}
                src={imageUrl}
                onProcess={(res) =>{
                    setInlineResult(URL.createObjectURL(res.dest))
                    handleClick(res.dest)
                     }
                }
                stickers={[
                  busur
              ]}
            /> */}

        <FilerobotImageEditor
          source={imageUrl}
          onSave={(editedImageObject, designState) => {
            console.log("saved", editedImageObject, designState, pasien)
            handleClick(editedImageObject)
          }}
          onClose={closeImgEditor}
          annotationsCommon={{
            fill: "#ff0000",
          }}
          Text={{ text: "Ashoka..." }}
          Rotate={{ angle: 90, componentType: "slider" }}
          
          tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.WATERMARK]} // or {['Adjust', 'Annotate', 'Watermark']}
          defaultTabId={TABS.ANNOTATE} // or 'Annotate'
          defaultToolId={TOOLS.TEXT} // or 'Text'
        />
      </Box>
    </Box>
  );
}

export default Pintura;
