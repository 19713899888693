import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import {
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Header from "../../components/Header";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { UserAuth } from "../../context/AuthContext";
import Alert from "@mui/material/Alert";

export default function Details(props) {
  const theme = useTheme();
  const params = useParams();
  const { user } = UserAuth();
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [residence, setResidence] = useState("");
  const [userType, setUserType] = useState("");

  const [editDialog, setEditDialog] = useState(false);
  const [hapusDialog, sethapusDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleEditClick = () => {
    setEditDialog(true);
  };

  const handleHapusClick = () => {
    sethapusDialog(true);
  };

  const handleDialogClose = () => {
    setEditDialog(false);
  };

  const handleHapusDialog = () => {
    sethapusDialog(false);
  };

  const deleteHandler = () => {
    sethapusDialog(false);
    setLoading(true);

    fetch("https://ashokanew-bjh4uafqtq-uc.a.run.app/user/"+params.idUser, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
        Admin: "true",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        navigate("/orangtua");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const editHandler = () => {
    setLoading(false);
    setEditDialog(false);
    setLoading(true);
    const updatedData = {
      email: email,
      fullName: fullName,
      residence: residence,
      id: params.idUser,
      phoneNumber: phoneNumber,
      imageUrl: imageUrl,
      userType: userType,
    };

    fetch("https://ashokanew-bjh4uafqtq-uc.a.run.app/user", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
        Admin: "true",
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => response.json())
      .then((data) => {
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://ashokanew-bjh4uafqtq-uc.a.run.app/user/" + params.idUser,
          {
            headers: {
              Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
              Admin: "true",
            },
          }
        );
        const datanya = await response.json();

        if (!datanya.error && datanya.user) {
          setData(datanya.user);
          setEmail(datanya.user.email);
          setFullName(datanya.user.fullName);
          setImageUrl(datanya.user.imageUrl);
          setPhoneNumber(datanya.user.phoneNumber);
          setResidence(datanya.user.residence);
          setUserType(datanya.user.userType);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, [params.idUser]);

  if (!data || Object.keys(data).length === 0) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Box m="15px">
      <Header title="Detail Data User" />

      {data && (
        <ThemeProvider theme={theme}>
          <Container component="main">
            <CssBaseline />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box component="form" noValidate sx={{ mt: 2, p: 1 }}>
                {success && (
                  <Alert sx={{ mb: 1 }} severity="success">
                    Berhasil Mengubah User!
                  </Alert>
                )}
                {error && (
                  <Alert sx={{ mb: 1 }} severity="error">
                    Gagal Mengubah User!
                  </Alert>
                )}
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <TextField
                      size="small"
                      autoComplete="fullName"
                      name="fullName"
                      required
                      fullWidth
                      id="fullName"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            Nama Lengkap:
                          </InputAdornment>
                        ),
                      }}
                      value={fullName}
                      onChange={(e) => {
                        setFullName(e.target.value);
                        console.log(e.target.value);
                      }}
                      sx={{ input: { textAlign: "end" } }}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Box display="flex" justifyContent="end" alignItems="end">
                      <Button
                        onClick={handleEditClick}
                        variant="contained"
                        sx={{ mr: 1 }}
                      >
                        Edit
                      </Button>
                      {props.role === "superadmin" && (
                        <Button
                          onClick={handleHapusClick}
                          variant="contained"
                          color="error"
                        >
                          Delete
                        </Button>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      size="small"
                      autoComplete="email"
                      name="email"
                      required
                      fullWidth
                      id="email"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            Email:
                          </InputAdornment>
                        ),
                      }}
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      sx={{ input: { textAlign: "end" } }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="tipeUser">Tipe User</InputLabel>
                      <Select
                        labelId="tipeUser"
                        id="demo-simple-select"
                        value={userType}
                        label="Tipe User"
                        onChange={(e) => {
                          setUserType(e.target.value);
                        }}
                      >
                        <MenuItem value={"Pengguna Umum"}>
                          Pengguna Umum
                        </MenuItem>
                        <MenuItem value={"Dokter Umum"}>Dokter Umum</MenuItem>
                        <MenuItem value={"Dokter Spesialis Urology"}>
                          Dokter Spesialis Urology
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      size="small"
                      autoComplete="Kontak"
                      name="Kontak"
                      required
                      fullWidth
                      id="Kontak"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            Kontak:
                          </InputAdornment>
                        ),
                      }}
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                      sx={{ input: { textAlign: "end" } }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
                    <TextField
                      size="small"
                      autoComplete="residence"
                      name="residence"
                      required
                      fullWidth
                      id="residence"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            Domisili:
                          </InputAdornment>
                        ),
                      }}
                      value={residence}
                      onChange={(e) => {
                        setResidence(e.target.value);
                      }}
                      sx={{ input: { textAlign: "end" } }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      )}
      <Dialog
        open={editDialog}
        onClose={handleDialogClose}
        aria-labelledby="verification-dialog-title"
        aria-describedby="verification-dialog-description"
      >
        <DialogTitle id="verification-dialog-title">
          Konfirmasi Edit
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            color="text.primary"
            id="verification-dialog-description"
          >
            Apakah Anda yakin ingin mengedit data ini?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Batal
          </Button>
          <Button onClick={editHandler} variant="contained" color="primary">
            Edit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={hapusDialog}
        onClose={handleHapusDialog}
        aria-labelledby="verification-dialog-title"
        aria-describedby="verification-dialog-description"
      >
        <DialogTitle id="verification-dialog-title">
          Konfirmasi Hapus
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            color="text.primary"
            id="verification-dialog-description"
          >
            Apakah Anda yakin ingin menghapus data ini?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHapusDialog} color="primary">
            Batal
          </Button>
          <Button onClick={deleteHandler} variant="contained" color="error">
            Hapus
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
