import { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material";
import Header from "../../components/Header";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { MuiTelInput } from "mui-tel-input";
import { UserAuth } from "../../context/AuthContext";

export default function SendReminder() {
  const theme = useTheme();

  const [phone, setPhone] = useState("");
  const [pesan, setPesan] = useState("");
  const [empty, setEmpty] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false)

  const {user} = UserAuth()

  const phoneChange = (newPhone) => {
    setPhone(newPhone);
  };

  const clearHandle = () => {
    setPhone("");
    setPesan("")
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setEmpty(false)
    setSuccess(false)
    setError(false)

    if (
      !phone ||
      !pesan
    ) {
      setEmpty(true);
      return;
    }
    setEmpty(false);
    setLoading(true);

    const payload = {
      phoneNumber: phone.replace(/[+ ]/g, ""),
      message: pesan
    };

    fetch("https://ashokanew-bjh4uafqtq-uc.a.run.app/reminder", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
        Admin: "true",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        setSuccess(true);
        setLoading(false);

        clearHandle()

      })
      .catch((error) => {
        setLoading(false)
        setError(true)
        clearHandle()
      });
  };

  return (
    <Box m="20px">
      <Header
        title="Send Reminder"
        subtitle="Kirim pesan melalui nomor pengguna"
      />

      <ThemeProvider theme={theme}>
        <Container component="main">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              noValidate
              sx={{ mt: 2, p: 1 }}

            >
              {empty && (
                <Alert sx={{ mb: 1 }} severity="warning">
                  Field Belum Lengkap!
                </Alert>
              )}
              {success && (
                <Alert sx={{ mb: 1 }} severity="success">
                  Berhasil Mengirim reminder!
                </Alert>
              )}
              {error && (
                <Alert sx={{ mb: 1 }} severity="error">
                  Gagal Mengirim reminder!
                </Alert>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <MuiTelInput
                    label="Nomor Whatsapp"
                    fullWidth
                    defaultCountry="ID"
                    value={phone}
                    onChange={phoneChange}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    id="outlined-multiline-static"
                    label="Pesan"
                    multiline
                    rows={4}
                    value={pesan}
                    onChange={(e) => setPesan(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={clearHandle}
                      sx={{ mt: 3, mb: 2, mr: 1 }}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
