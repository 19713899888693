import { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import Container from "@mui/material/Container";
import {
  Box,
  useTheme,
  createTheme,
  ThemeProvider,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Avatar,
  Divider,
} from "@mui/material";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteIcon from "@mui/icons-material/Delete";
import Header from "../../components/Header";
import {
  Chart,
  BarSeries,
  PieSeries,
  Title,
  ArgumentAxis,
  ValueAxis,
  Legend,
  Tooltip,
} from "@devexpress/dx-react-chart-material-ui";
import { Stack, Animation } from "@devexpress/dx-react-chart";
import { EventTracker } from "@devexpress/dx-react-chart";
import { tokens } from "../../theme";
const Root = (props) => (
  <Legend.Root
    {...props}
    sx={{
      display: "flex",
      margin: "auto",
      flexDirection: "row",
      overflow: "hidden",
    }}
  />
);

const Labela = (props) => (
  <Legend.Label {...props} sx={{ whiteSpace: "nowrap" }} />
);

export default function Portfolio() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const param = useParams();
  const { search } = useLocation();

  const [data, setData] = useState(null);

  const boxStyles = {
    bgcolor: colors.primary[400],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "35px",
    paddingBottom: "35px",
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://ashokanew-bjh4uafqtq-uc.a.run.app/stats/portofolio/" +
            param.idUser
        );
        const data = await response.json();
        console.log(data);

        if (data) {
          setData(data);
        } else {
          setData(null);
        }

        // // check if there query download in url
        // const queryParams = new URLSearchParams(search);
        // const downloadParam = queryParams.get("download");

        // if (downloadParam === "true") {
        //   let domElement = document.getElementById("porto");
        //   htmlToImage
        //     .toPng(domElement)
        //     .then(function (dataUrl) {
        //       const pdf = new jsPDF({
        //         orientation: "portrait",
        //         unit: "mm",
        //         format: "a4",
        //       });
        //       pdf.addImage(dataUrl, "PNG", 7, 5, 200, 280);
        //       pdf.save("downloadss.pdf");
        //     })
        //     .catch(function (error) {
        //       console.error("oops, something went wrong!", error);
        //     });
        // }
      } catch (err) {}
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Check if there is a "download" query parameter in the URL after data is loaded
    if (data) {
      const queryParams = new URLSearchParams(search);
      const downloadParam = queryParams.get("download");

      if (downloadParam === "true") {
        let domElement = document.getElementById("porto");
        htmlToImage
          .toPng(domElement)
          .then(function (dataUrl) {
            const pdf = new jsPDF({
              orientation: "portrait",
              unit: "mm",
              format: "a4",
            });
            pdf.addImage(dataUrl, "PNG", 7, 5, 200, 280);
            pdf.save("downloadss.pdf");
          })
          .catch(function (error) {
            console.error("Oops, something went wrong!", error);
          });
      }
    }
  }, [data, search]); // Add data and search to the dependency array

  return (
    <div>
      {data && (
        <ThemeProvider theme={theme}>
          <Container component="main" maxWidth={false}>
            <Grid
              container
              sx={{ mt: 1, width: "210mm", height: "297mm" }}
              id="porto"
            >
              <Typography
                variant="subtitle2"
                fontWeight="light"
              >{`https://ashoka-dashboard.com/portofolio/${param.idUser}`}</Typography>
              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  fontWeight={"bold"}
                  color={colors.primary[400]}
                >
                  Portofolio Pribadi Pediatri Urologi
                </Typography>
              </Grid>

              <Grid item xs={12} sx={{ mt: 1 }}>
                <Typography variant="h3" gutterBottom>
                  {`dr. ${data.name}, Sp.U(K)`}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography
                  variant="h2"
                  fontWeight={"bold"}
                  color={colors.primary[400]}
                >
                  {`${data.practiceLocation[0]}`}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography
                  variant="h4"
                  fontWeight={"bold"}
                  color={colors.primary[400]}
                >
                  Pasien Hipospadia
                </Typography>
              </Grid>

              <Grid item xs={6} sx={{ mt: 1 }}>
                <Box>
                  <List>
                    <ListItem
                      secondaryAction={
                        <Typography variant="subtitle">
                          {data.stats && data.stats.meatusLocations
                            ? data.stats.meatusLocations.Glanular
                            : 0}
                        </Typography>
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography variant="subtitle" fontWeight={"bold"}>
                            Glanular
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <Typography variant="subtitle">
                          {data.stats && data.stats.meatusLocations
                            ? data.stats.meatusLocations.Distal
                            : 0}
                        </Typography>
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography variant="subtitle" fontWeight={"bold"}>
                            Distal
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <Typography variant="subtitle">
                          {data.stats && data.stats.meatusLocations
                            ? data.stats.meatusLocations.Perineal
                            : 0}
                        </Typography>
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography variant="subtitle" fontWeight={"bold"}>
                            Perineal
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <Typography variant="subtitle">
                          {data.stats && data.stats.meatusLocations
                            ? data.stats.meatusLocations.Proximal
                            : 0}
                        </Typography>
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography variant="subtitle" fontWeight={"bold"}>
                            Proximal
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </Box>
              </Grid>

              <Grid item xs={6} sx={{ mt: 1 }}>
                <Box>
                  <Chart
                    height={250}
                    data={
                      data.stats && data.stats.operationTechniques
                        ? Object.entries(data.stats.operationTechniques)
                            .map(([operationTechnique, count]) => ({
                              tipe: operationTechnique,
                              jumlah: count,
                            }))
                            .filter((entry) => entry.jumlah !== 0)
                        : []
                    }
                  >
                    <PieSeries
                      name="Teknik Operasi"
                      valueField="jumlah"
                      argumentField="tipe"
                      color="#0237A1"
                    />
                    <Title text="Teknik Operasi" />
                    <Animation />
                    <EventTracker />
                    <Legend
                      position="bottom"
                      rootComponent={Root}
                      labelComponent={Labela}
                    />
                    <Tooltip />
                    <Stack />
                  </Chart>
                </Box>
              </Grid>

              <Grid item xs={6} sx={{ mt: 1 }}>
                <Box>
                  <Chart
                    height={250}
                    data={
                      data.stats && data.stats.chordee
                        ? Object.entries(data.stats.chordee)
                            .map(([chordee, count]) => ({
                              tipe: chordee,
                              jumlah: count,
                            }))
                            .filter((entry) => entry.jumlah !== 0)
                        : []
                    }
                  >
                    <PieSeries
                      name="Chordee Derajat Berat"
                      valueField="jumlah"
                      argumentField="tipe"
                      color="#0237A1"
                    />
                    <Title text="Chordee Derajat Berat" />
                    <Animation />
                    <EventTracker />
                    <Legend
                      position="bottom"
                      rootComponent={Root}
                      labelComponent={Labela}
                    />
                    <Tooltip />
                    <Stack />
                  </Chart>
                </Box>
              </Grid>
              <Grid item xs={6} sx={{ mt: 1 }}>
                <Box>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={
                          <Typography
                            variant="h4"
                            fontWeight={"bold"}
                            color={colors.primary[400]}
                          >
                            Komplikasi
                          </Typography>
                        }
                      />
                    </ListItem>
                    <Divider />
                    <ListItem
                      secondaryAction={
                        <Typography variant="subtitle">
                          {data.stats &&
                          data.stats.komplikasi &&
                          data.stats.komplikasi.Fistel
                            ? data.stats.komplikasi.Fistel
                            : 0}
                        </Typography>
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography variant="subtitle" fontWeight={"bold"}>
                            Fistel
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <Typography variant="subtitle">
                          {data.stats &&
                          data.stats.komplikasi &&
                          data.stats.komplikasi["Wound Dehiscence"]
                            ? data.stats.komplikasi["Wound Dehiscence"]
                            : 0}
                        </Typography>
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography variant="subtitle" fontWeight={"bold"}>
                            Wound Dehiscence
                          </Typography>
                        }
                      />
                    </ListItem>
                    <ListItem
                      secondaryAction={
                        <Typography variant="subtitle">
                          {data.stats &&
                          data.stats.komplikasi &&
                          data.stats.komplikasi["Pancaran Berkemih"]
                            ? data.stats.komplikasi["Pancaran Berkemih"]
                            : 0}
                        </Typography>
                      }
                    >
                      <ListItemText
                        primary={
                          <Typography variant="subtitle" fontWeight={"bold"}>
                            Pancaran Berkemih
                          </Typography>
                        }
                      />
                    </ListItem>
                  </List>
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography
                  variant="h3"
                  fontWeight={"bold"}
                  color={colors.primary[400]}
                >
                  Pasien Kelainan Genitalia Lain
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Chart
                  height={250}
                  data={
                    data.stats && data.stats.sirkumPenisShapes
                      ? Object.entries(data.stats.sirkumPenisShapes).map(
                          ([operationTechnique, count]) => ({
                            tipe: operationTechnique,
                            jumlah: count,
                          })
                        )
                      : []
                  }
                >
                  <ArgumentAxis />
                  <ValueAxis />

                  <BarSeries
                    name="Operation Technique"
                    valueField="jumlah"
                    argumentField="tipe"
                    color="#0237A1"
                  />
                  <Title text="" />
                  <Animation />
                  <EventTracker />

                  <Tooltip />
                  <Stack />
                </Chart>
              </Grid>
            </Grid>
          </Container>
        </ThemeProvider>
      )}
    </div>
  );
}
