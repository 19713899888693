import {
    Box,
    Paper,
    Grid,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Button,
  } from "@mui/material";

  import Carousel from "react-material-ui-carousel";
  import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
  import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
  
  const Header = ({ title, subtitle }) => {
  

    return (
      <Carousel 
      autoPlay={false} navButtonsAlwaysVisible NextIcon={<KeyboardArrowRightIcon />} PrevIcon={<KeyboardArrowLeftIcon />}>
        <Box
          m="10px"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Paper sx={{ p: 1 }}>
            <img
              src="https://cdn.pixabay.com/photo/2016/03/05/19/02/hamburger-1238246_1280.jpg"
              alt="burger"
              width={"100%"}
              height="300px"
            />
            <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{mt: 1}}>
                  <InputLabel id="validity-1">Validity</InputLabel>
                  <Select
                    labelId="glans"
                    id="demo-simple-select"
                    label="Validity"
                  >
                    <MenuItem value={"Valid"}> Valid</MenuItem>
                    <MenuItem value={"Tidak Valid"}>Tidak Valid</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
          
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="bentukpenis-1">Bentuk Penis</InputLabel>
                  <Select
                    labelId="metatus"
                    id="demo-simple-select"
                    label="Bentuk penis"
                  >
                    <MenuItem value={"Normal"}>Normal</MenuItem>
                    <MenuItem value={"Hipospadia"}>Hipospadia</MenuItem>
                    <MenuItem value={"Epispadia"}>Epispadia</MenuItem>
                    <MenuItem value={"WebedPenis"}>WebedPenis</MenuItem>
                    <MenuItem value={"Chordee"}>Chordee</MenuItem>
                    <MenuItem value={"Ambiguous genitalia"}>Ambiguous genitalia</MenuItem>
                    <MenuItem value={"Lainnya"}>Lainnya</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
              <FormControl fullWidth>
                  <InputLabel id="chordee-1">Chordee</InputLabel>
                  <Select
                    labelId="urethalplate"
                    id="demo-simple-select"
                    label="Chordee"
                  >
                    <MenuItem value={"Tidak ada"}>Tidak ada</MenuItem>
                    <MenuItem value={"Mild"}>Mild</MenuItem>
                    <MenuItem value={"Moderate"}>Moderate</MenuItem>
                    <MenuItem value={"Severe"}>Severe</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                  <Button variant="contained" sx={{ mb: 2 }}>
                    Edit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Box>
        <Box
          m="10px"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Paper sx={{ p: 1 }}>
            <img
              src="https://cdn.pixabay.com/photo/2016/03/05/19/02/hamburger-1238246_1280.jpg"
              alt="burger"
              width={"100%"}
              height="300px"
            />
            <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{mt: 1}}>
                  <InputLabel id="validity-1">Validity</InputLabel>
                  <Select
                    labelId="glans"
                    id="demo-simple-select"
                    label="Validity"
                  >
                    <MenuItem value={"Valid"}> Valid</MenuItem>
                    <MenuItem value={"Tidak Valid"}>Tidak Valid</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
          
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="bentukpenis-1">Bentuk Penis</InputLabel>
                  <Select
                    labelId="metatus"
                    id="demo-simple-select"
                    label="Bentuk penis"
                  >
                    <MenuItem value={"Normal"}>Normal</MenuItem>
                    <MenuItem value={"Hipospadia"}>Hipospadia</MenuItem>
                    <MenuItem value={"Epispadia"}>Epispadia</MenuItem>
                    <MenuItem value={"WebedPenis"}>WebedPenis</MenuItem>
                    <MenuItem value={"Chordee"}>Chordee</MenuItem>
                    <MenuItem value={"Ambiguous genitalia"}>Ambiguous genitalia</MenuItem>
                    <MenuItem value={"Lainnya"}>Lainnya</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
              <FormControl fullWidth>
                  <InputLabel id="chordee-1">Chordee</InputLabel>
                  <Select
                    labelId="urethalplate"
                    id="demo-simple-select"
                    label="Chordee"
                  >
                    <MenuItem value={"Tidak ada"}>Tidak ada</MenuItem>
                    <MenuItem value={"Mild"}>Mild</MenuItem>
                    <MenuItem value={"Moderate"}>Moderate</MenuItem>
                    <MenuItem value={"Severe"}>Severe</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                  <Button variant="contained" sx={{ mb: 2 }}>
                    Edit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Box>
        <Box
          m="10px"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Paper sx={{ p: 1 }}>
            <img
              src="https://cdn.pixabay.com/photo/2016/03/05/19/02/hamburger-1238246_1280.jpg"
              alt="burger"
              width={"100%"}
              height="300px"
            />
            <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth sx={{mt: 1}}>
                  <InputLabel id="validity-1">Validity</InputLabel>
                  <Select
                    labelId="glans"
                    id="demo-simple-select"
                    label="Validity"
                  >
                    <MenuItem value={"Valid"}> Valid</MenuItem>
                    <MenuItem value={"Tidak Valid"}>Tidak Valid</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
          
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="bentukpenis-1">Bentuk Penis</InputLabel>
                  <Select
                    labelId="metatus"
                    id="demo-simple-select"
                    label="Bentuk penis"
                  >
                    <MenuItem value={"Normal"}>Normal</MenuItem>
                    <MenuItem value={"Hipospadia"}>Hipospadia</MenuItem>
                    <MenuItem value={"Epispadia"}>Epispadia</MenuItem>
                    <MenuItem value={"WebedPenis"}>WebedPenis</MenuItem>
                    <MenuItem value={"Chordee"}>Chordee</MenuItem>
                    <MenuItem value={"Ambiguous genitalia"}>Ambiguous genitalia</MenuItem>
                    <MenuItem value={"Lainnya"}>Lainnya</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
              <FormControl fullWidth>
                  <InputLabel id="chordee-1">Chordee</InputLabel>
                  <Select
                    labelId="urethalplate"
                    id="demo-simple-select"
                    label="Chordee"
                  >
                    <MenuItem value={"Tidak ada"}>Tidak ada</MenuItem>
                    <MenuItem value={"Mild"}>Mild</MenuItem>
                    <MenuItem value={"Moderate"}>Moderate</MenuItem>
                    <MenuItem value={"Severe"}>Severe</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                  <Button variant="contained" sx={{ mb: 2 }}>
                    Edit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Carousel>
    );
  };
  
  export default Header;
  