import { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material";
import Header from "../../components/Header";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

export default function SendReminder() {
  const theme = useTheme();

  const [email, setEmail] = useState("");
  const [pesan, setPesan] = useState("");
  const [pengirim, setPengirim] = useState("");
  const [tipe, setTipe] = useState("");
  const [title, setTitle] = useState("");

  const [empty, setEmpty] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errornotification, setErrorNotification] = useState(false)
  const [loading, setLoading] = useState(false);


  const clearHandle = () => {
    setPesan("");
    setEmail("");
    setPengirim("");
    setTipe("");
    setErrorNotification(false)
    setSuccess(false)
    setLoading(false)
    setTitle("");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorNotification(false)
    setEmpty(false);
    setSuccess(false);

    if (!email || !pesan || !pengirim || !tipe || !title) {
      setEmpty(true);
      return;
    }
    setEmpty(false);
    setLoading(true);

    const payload = {
      email: email,
      message: pesan,
      sender: pengirim,
      tipe: tipe,
      title: title,
    };

    fetch("https://backend-ashoka-bjh4uafqtq-uc.a.run.app/admin/notification", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        console.log(response)
        if (!response.ok) {
          throw new Error("Network response was not ok");
        } else {
          return response.json(); 
        }
        
      })
      .then((data) => {
        clearHandle()
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setErrorNotification(true);
        setLoading(false)
        console.error("Error:", error);
      });
    
  };

  return (
    <Box m="20px">
      <Header title="Send Reminder" subtitle="Kirim pesan ke notifikasi user" />

      <ThemeProvider theme={theme}>
        <Container component="main">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box component="form" noValidate sx={{ mt: 2, p: 1 }}>
              {empty && (
                <Alert sx={{ mb: 1 }} severity="warning">
                  Field Belum Lengkap!
                </Alert>
              )}
              {success && (
                <Alert sx={{ mb: 1 }} severity="success">
                  Berhasil Mengirim reminder!
                </Alert>
              )}
              {errornotification && (
                <Alert sx={{ mb: 1 }} severity="error">
                  Email belum terdaftar!
                </Alert>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth={true}
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    type="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth={true}
                    id="from"
                    label="From"
                    name="from"
                    autoComplete="from"
                    type="from"
                    value={pengirim}
                    onChange={(event) => setPengirim(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth={true}>
                    <Select
                      value={tipe}
                      onChange={(e) => setTipe(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Select notification type" }}
                    >
                      <MenuItem value="" disabled>
                        Select notification type
                      </MenuItem>
                      <MenuItem value="information">Information</MenuItem>
                      <MenuItem value="warning">Warning</MenuItem>
                      <MenuItem value="schedule">Schedule</MenuItem>
                      <MenuItem value="validation">Validation</MenuItem>
                      <MenuItem value="error">Error</MenuItem>
                      <MenuItem value="feedback">Feedback</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth={true}
                    id="title"
                    label="Title"
                    name="title"
                    autoComplete="title"
                    type="title"
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    fullWidth
                    id="outlined-multiline-static"
                    label="Pesan"
                    multiline
                    rows={4}
                    value={pesan}
                    onChange={(e) => setPesan(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={clearHandle}
                      sx={{ mt: 3, mb: 2, mr: 1 }}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Send
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
