import {
  Typography,
  Box,
  useTheme,
  Paper,
  Grid,
  TextField,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
} from "@mui/material";
import { tokens } from "../theme";
import { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import { UserAuth } from "../context/AuthContext";
import { Dialog, DialogContent, DialogActions } from "@mui/material";
import { getEditorDefaults } from "@pqina/pintura";
import busur from "../images/busur.png";
import busurwarna from "../images/busurwarna.png";
import { PinturaEditor } from "@pqina/react-pintura";
const Header = ({ data, pasien }) => {
  const theme = useTheme();
  const { user } = UserAuth();
  const [inlineResult, setInlineResult] = useState();
  const [valid, setValid] = useState(data.validity !== undefined ? data.validity : "");
  const [meatus, setMeatus] = useState(data.meatusLocation || "");
  const [glans, setGlans] = useState(data.glansQuality || "");
  const [plate, setPlate] = useState(data.urethalPlate || "");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [imageAI, setImageAI] = useState("");
  const [labelAI, setLabelAI] = useState("");
  const [aiDialog, setAiDialog] = useState(false);
  const [dokter, setDokter] = useState({});
  const [aiError, setAIError] = useState(false);
  const [teknikOperasi, setTeknikOperasi] = useState(
    data.operationTechnique || ""
  );

  const [fistel, setFistel] = useState(data.fistel || "");
  const [dehiscence, setDehiscence] = useState(data.dehiscence || "");

  const handleDelete = () => {
    console.log(data.id)
    fetch("https://ashokanew-bjh4uafqtq-uc.a.run.app/stage/"+data.id, {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${user.stsTokenManager.accessToken}`,
        "Admin": "true",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSuccess(true);
        setError(false);
      })
      .catch((error) => {
        setError(true);
        setSuccess(false);
      });
  };

  const fistelMap = {
    Ya: 1,
    Tidak: 0,
  };

  const letakMeatusHipos = {
    Glanular: 2,
    "Distal shaft": 1,
    "Proximal shaft": 3,
    Perineal: 4,
  };

  const letakMeatusMap = {
    "Ujung penis": 1,
    Glanular: 2,
    "Mid shaft": 3,
    "Proximal shaft / penoscrotal": 4,
  };

  const kualitasGlansHipos = {
    "Groove baik": 1,
    "Groove kurang": 2,
    "Groove rata": 3,
  };

  const kualitasGlansMap = {
    "Ukuran baik, beralur dalam": 1,
    "Ukuran cukup, beralur": 2,
    "Ukuran kecil, fibrosis atau rata": 3,
    "Ukuran sangat kecil, sangat sempit atau rata": 4,
  };

  const urethalPlateMap = {
    Intak: 1,
    Terbagi: 2,
  };

  const kualitasPancaranMap = {
    "Sangat buruk/menetes": 1,
    Buruk: 2,
    Cukup: 3,
    Baik: 4,
    "Sangat Baik": 5,
  };

  const validityMap = {
    Valid: 1,
    "Tidak Valid": 0,
  };

  const teknikOperasiMap = {
    "Tubularized Incised Plate": 1,
    "Dorsal Inlay Graft": 2,
    "Dorsal Onlay Graft": 3,
    "Mathieu Urethroplasty": 4,
    "Two-Staged Urethroplasty": 5,
    "Re-do Urethroplasty": 6,
    "EPA Urethroplasty": 7,
    Lainnya: 8,
  };

  const handleAI = () => {
    const updatedData = {
      image_url: data.fileUrl,
      mask: 1,
      bbox: 1,
      save: 0,
    };

    try {
        fetch("https://backend-ashoka-ml-bjh4uafqtq-et.a.run.app/predict_url", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify(updatedData),
      })
        .then((response) => {
          if (response.headers.get("label") === "no detection") {
            setAIError(true);
          }

          setLabelAI(response.headers.get("label"));

          return response.blob();
        })
        .then((data) => {
          setAiDialog(true);
          setImageAI(URL.createObjectURL(data));
        })
        .catch((error) => {});
    } catch {
      console.log("test");
    }
  };

  const handleAIDialog = () => {
    setAiDialog(false);
  };

  const handleClick = async () => {
    setError(false);
    setSuccess(false);
    const updatedData = {
      meatusLocation: meatus,
      glansQuality: glans,
      validity: valid,
      urethalPlate: plate,
      id: data.id,
      idDoctor: "7ed91ec28dcb4f61ad6d4a2a6078cf15"
    };

    if (teknikOperasi !== "") {
      updatedData.operationTechnique = teknikOperasi;
    }

    if (fistel !== "") {
      updatedData.fistel = fistel;
    }

    if (dehiscence !== "") {
      updatedData.dehiscence = dehiscence;
    }

    try {
      const response = await fetch(
        "https://ashokanew-bjh4uafqtq-uc.a.run.app/stage",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
            Admin: "true",
          },
          body: JSON.stringify(updatedData),
        }
      );
      const result = await response.json();
      setSuccess(true)
      setError(false)
    } catch (error) {
      setSuccess(false)
      setError(true)
    }
  };

  const handleUbah = () => {
    setMeatus(labelAI);
    setAiDialog(false);
  };


  const errorAI = (
    <Alert sx={{ mt: 2 }} severity="error">
      No Detection
    </Alert>
  );

  return (
    <>
      <Dialog
        fullWidth
        open={aiDialog}
        onClose={handleAIDialog}
        aria-labelledby="verification-dialog-title"
        aria-describedby="verification-dialog-description"
      >
        {aiError ? (
          errorAI
        ) : (
          <DialogContent>
            <img
              src={imageAI}
              alt="burger"
              width={"100%"}
              height="300px"
              style={{ objectFit: "fill" }}
            />
            <TextField
              sx={{ mt: 2 }}
              required
              fullWidth={true}
              id="ai"
              label="Result"
              name="ai"
              autoComplete="ai"
              type="text"
              value={labelAI}
            />
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleAIDialog}>batal</Button>
          {!aiError && <Button onClick={handleUbah}>Ubah</Button>}
        </DialogActions>
      </Dialog>
      <Box
        m="10px"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Paper sx={{ p: 1, width: "100%" }}>
          {success && (
            <Alert sx={{ mb: 1 }} severity="success">
              Update Success!
            </Alert>
          )}
          {error && (
            <Alert sx={{ mb: 1 }} severity="error">
              Update Gagal!
            </Alert>
          )}

          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                mt: 2,
                height: 600,
              }}
            >
              <PinturaEditor
                {...getEditorDefaults()}
                src={data.fileUrl}
                enableButtonExport={false}
                util={"sticker"}
                enableUtils={false}
                enableToolbar={false}
                stickers={[busur, busurwarna]}
              />
            </Grid>
          
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ mt: 1 }}>
                <InputLabel id="validity-1">Validity</InputLabel>
                <Select
                  labelId="glans"
                  id="valid-select"
                  label="Validity"
                  value={valid}
                  onChange={(event) => setValid(event.target.value)}
                >
                  <MenuItem name={`validity1`} value={true} key={`validity1`}>
                      true
                    </MenuItem>
                    <MenuItem name={`validity2`} value={false} key={`validity2`}>
                      false
                    </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={pasien.patientType === "Hipospadia" ? 9 : 12}>
              <FormControl fullWidth>
                <InputLabel id="letakmeatus-1">Letak Meatus</InputLabel>
                <Select
                  labelId="metatus"
                  id="meatus-select"
                  label="Letak Meatus"
                  value={meatus}
                  onChange={(event) => setMeatus(event.target.value)}
                >
                  {Object.keys(
                    pasien.patientType === "Hipospadia"
                      ? letakMeatusHipos
                      : letakMeatusMap
                  ).map((key) => (
                    <MenuItem
                      value={key}
                      key={
                        pasien.patientType === "Hipospadia"
                          ? letakMeatusHipos[key]
                          : letakMeatusMap[key]
                      }
                    >
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {pasien.patientType === "Hipospadia" && (
              <Grid item xs={3}>
                <Button
                  sx={{ height: "100%" }}
                  fullWidth
                  onClick={handleAI}
                  variant="contained"
                >
                  AI
                </Button>
              </Grid>
            )}

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="glans-1">Kualitas Glans</InputLabel>
                <Select
                  labelId="glans"
                  id="glans-select"
                  label="Kualitas Glans"
                  value={glans}
                  onChange={(event) => setGlans(event.target.value)}
                >
                  {Object.keys(
                    pasien.patientType === "Hipospadia"
                      ? kualitasGlansHipos
                      : kualitasGlansMap
                  ).map((key) => (
                    <MenuItem
                      value={key}
                      key={
                        pasien.patientType === "Hipospadia"
                          ? kualitasGlansHipos[key]
                          : kualitasGlansMap[key]
                      }
                    >
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="plate-1">Urethal Plate</InputLabel>
                <Select
                  labelId="urethal"
                  id="urethal-select"
                  label="Urethal Plate"
                  value={plate}
                  onChange={(event) => setPlate(event.target.value)}
                >
                  {Object.keys(urethalPlateMap).map((key) => (
                    <MenuItem value={key} key={urethalPlateMap[key]}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {data.progress === 2 ? (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="teknikOperasi-1">Teknik Operasi</InputLabel>
                  <Select
                    labelId="teknikOperasi"
                    id={"bentuk-select" + data.imageUrl}
                    label="Teknik Operasi"
                    value={teknikOperasi}
                    onChange={(event) => setTeknikOperasi(event.target.value)}
                    name="teknikOperasi"
                  >
                    {Object.keys(teknikOperasiMap).map((key) => (
                      <MenuItem
                        name={key}
                        value={key}
                        key={teknikOperasiMap[key]}
                      >
                        {key}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              ""
            )}

            {data.progress >= 4 ? (
              <Grid item xs={12}>
                <TextField
                  label="Fistel"
                  name="fistel"
                  fullWidth
                  id="fistel"
                  value={fistel}
                  onChange={(event) => setFistel(event.target.value)}
                />
              </Grid>
            ) : (
              ""
            )}

            {data.progress >= 4 ? (
              <Grid item xs={12}>
                <TextField
                  label="Wound Dehiscence"
                  name="placeOfBirth"
                  fullWidth
                  id="ttl"
                  value={dehiscence}
                  onChange={(event) => setDehiscence(event.target.value)}
                />
              </Grid>
            ) : (
              ""
            )}

            <Grid item xs={12}>
              <Grid container justifyContent="center" alignItems="center">
                <Button
                  onClick={handleClick}
                  variant="contained"
                  sx={{ mb: 2, mr: 1 }}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDelete}
                  sx={{ mb: 2 }}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default Header;
