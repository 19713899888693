import {
  Typography,
  Box,
  useTheme,
  Paper,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
} from "@mui/material";
import { tokens } from "../theme";
import ReactPlayer from "react-player";
import { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import { UserAuth } from "../context/AuthContext";

const Header = ({ data, pasien }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [valid, setValid] = useState(data.validity !== undefined ? data.validity : "");
  const [kualitas, setKualitas] = useState(data.urineStream || "");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [dokter, setDokter] = useState({});
  const { user } = UserAuth();

  const handleDelete = () => {
    console.log(data.id);
    fetch("https://ashokanew-bjh4uafqtq-uc.a.run.app/stage/" + data.id, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
        Admin: "true",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSuccess(true);
        setError(false);
      })
      .catch((error) => {
        setError(true);
        setSuccess(false);
      });
  };

  const kualitasPancaranMap = {
    "Sangat buruk/menetes": 1,
    Buruk: 2,
    Cukup: 3,
    Baik: 4,
    "Sangat Baik": 5,
  };

  const validityMap = {
    Valid: 1,
    "Tidak Valid": 0,
  };

  const handleClick = async () => {
    const updatedData = {
      validity: valid,
      urineStream: kualitas,
      id: data.id,
      idDoctor: "7ed91ec28dcb4f61ad6d4a2a6078cf15"
    };
    console.log(updatedData);
    try {
      const response = await fetch(
        "https://ashokanew-bjh4uafqtq-uc.a.run.app/stage",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
            Admin: "true",
          },
          body: JSON.stringify(updatedData),
        }
      );
      const result = await response.json();
      setSuccess(true)
      setError(false)
    } catch (error) {
      setSuccess(false)
      setError(true)
    }
  };

  return (
    <Box
      m="10px"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Paper sx={{ p: 1, width: "100%" }}>
        {success && (
          <Alert sx={{ mb: 1 }} severity="success">
            Update Success!
          </Alert>
        )}
        {error && (
          <Alert sx={{ mb: 1 }} severity="error">
            Update Gagal!
          </Alert>
        )}

        <ReactPlayer
          playing={true}
          muted={true}
          controls={true}
          width={"100%"}
          height="200px"
          url={data.fileUrl}
        />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ mt: 1 }}>
              <InputLabel id="validity-1">Validity</InputLabel>
              <Select
                labelId="glans"
                id="valid-select"
                label="Validity"
                value={valid}
                onChange={(event) => setValid(event.target.value)}
              >
                <MenuItem name={`validity1`} value={true} key={`validity1`}>
                      true
                    </MenuItem>
                    <MenuItem name={`validity2`} value={false} key={`validity2`}>
                      false
                    </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="kualitas-1">Kualitas Pancaran</InputLabel>
              <Select
                labelId="kualitas"
                id="kualitas-select"
                label="Kualitas Pancaran"
                value={kualitas}
                onChange={(event) => setKualitas(event.target.value)}
              >
                {Object.keys(kualitasPancaranMap).map((key) => (
                  <MenuItem value={key} key={kualitasPancaranMap[key]}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
              <Button
                onClick={handleClick}
                variant="contained"
                sx={{ mb: 2, mr: 1 }}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                color="error"
                onClick={handleDelete}
                sx={{ mb: 2 }}
              >
                Delete
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Header;
