import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import UploadIcon from "@mui/icons-material/CloudUpload";
import atas from "../images/atas.png";
import bawah from "../images/bawah.png";
import samping from "../images/samping.png";
import video from "../images/video.png";

const SectionAccordion = ({
  title,
  data,
  expanded,
  onChange,
  items,
  progress,
  matches,
  progressOneHandler,
  handleImageUpload,
}) => {
  const itemData = [
    {
      img: samping,
      title: "Penis Sisi Samping",
      author: data.fullName,
      tipe: 1,
    },
    {
      img: bawah,
      title: "Penis Sisi Bawah",
      author: data.fullName,
      tipe: 2,
    },
    {
      img: atas,
      title: "Penis Sisi Atas",
      author: data.fullName,
      tipe: 3,
    },
    {
      img: video,
      title: "Video",
      author: data.fullName,
      tipe: 4,
    },
  ];
  const filteredItems = itemData.filter((item) =>
    items === 1 || items === 2 ? item.tipe !== 4 : true
  );
  return (
    <Accordion
      TransitionProps={{
        timeout: 0,
      }}
      expanded={expanded}
      onChange={onChange}
      disableGutters
      disabled={progress < items}
      sx={{ mb: 3 }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography>{title}</Typography>
      </AccordionSummary>

      <AccordionDetails>
        <ImageList cols={4} sx={{ width: "100%", mt: -2 }}>
          <ImageListItem key="Subheader" cols={4}>
            <Typography variant="caption">
              Pemeriksaan pertama Anak oleh Dokter
            </Typography>
          </ImageListItem>
          {filteredItems.map((item) => (
            <ImageListItem
              cols={matches ? 1 : 4}
              key={item.img}
              sx={{ height: 250 }}
            >
              <img
                src={`${item.img}`}
                srcSet={`${item.img}`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={item.title}
                subtitle={item.author}
                actionIcon={
                  <div>
                    <IconButton
                      sx={{
                        color: "rgba(255, 255, 255, 0.54)",
                        marginRight: "2px",
                      }}
                      aria-label={`info about ${item.title}`}
                      onClick={() => {
     
                        progressOneHandler(item.tipe, items);
                      }}
                    >
                      <InfoIcon sx={{ mr: 2 }} />
                    </IconButton>
                    <IconButton
                      sx={{
                        color: "rgba(255, 255, 255, 0.54)",
                        marginRight: "2px",
                      }}
                      aria-label={`info about ${item.title}`}
                    >
                      <label htmlFor={`image2-upload-${item.title} ${items}`}>
                        <input
                          id={`image2-upload-${item.title} ${items}`}
                          type="file"
                          accept="*"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            console.log(data);
                            handleImageUpload(e, item.tipe, items);
                          }}
                        />
                        <UploadIcon />
                      </label>
                    </IconButton>
                  </div>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </AccordionDetails>
    </Accordion>
  );
};

export default SectionAccordion;
