import {
  Box,
  useTheme,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { UserAuth } from "../../context/AuthContext";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
const Pasien = () => {
  const [data, setData] = useState([]);
  const [dokterData, setDokterData] = useState({});
  const { user } = UserAuth();
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://ashokanew-bjh4uafqtq-uc.a.run.app/user?field=verified&value=false",
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
              Admin: "true",
            },
          }
        );
        const data = await response.json();

        const list = data.user.filter(
          (item) => item.userType !== "Pengguna Umum"
        )


        setData(list);
      } catch (err) {}
    };

    fetchData();
  }, []);

  const [openDialog, setOpenDialog] = useState(false);

  const handleDetailClick = (pasiennya) => {
    setDokterData(pasiennya);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const verifHandler = () => {
    setOpenDialog(false);

    fetch("https://ashokanew-bjh4uafqtq-uc.a.run.app/verify/"+dokterData.id, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
        Admin: "true",
      },
    })
      .then((response) => response.json())
      .then((data) => window.location.reload(false))
      .catch((error) => console.error(error));
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "fullName",
      headerName: "Full Name",
      flex: 1,
    },
    {
      field: "npaIdi",
      headerName: "NPA IDI",
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "residence",
      headerName: "Residence",
      flex: 1,
    },
    {
      field: "userType",
      headerName: "User Type",
      flex: 1,
    },
    {
      field: "verified",
      headerName: "Verified",
      flex: 1,
    },
    {
      field: "Detail",
      headerName: "Detail",
      sortable: false,
      filterable: false,
      width: 120,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          startIcon={<ZoomInIcon />}
          onClick={() => {
            handleDetailClick(params.row);
          }}
        >
          Verifikasi
        </Button>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="Verifikasi" subtitle="List Unverified Dokter" />
      <Box
        sx={{
          mt: 2,
          height: 600,
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            color: colors.blueAccent[900],
            backgroundColor: colors.primary[900],
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            backgroundColor: colors.primary[900],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[600],
            color: "#ffffff",
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[900],
            color: "#ffffff",
          },

          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[600],
          },
          "& .MuiTablePagination-root": {
            color: "#ffffff",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.redAccent[300]} !important`,
          },
          "& .MuiDataGrid-sortIcon": {
            color: "#ffffff",
          },
          "& .css-196n7va-MuiSvgIcon-root": {
            color: "#ffffff",
          },
        }}
      >
        <DataGrid
          pagination
          density="standard"
          autoHeight={false} // Disable autoHeight
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 25, 100]}
          rows={data}
          columns={columns}
          components={{ Toolbar: CustomToolbar }}
          getRowId={(row) => row.id}
        />
      </Box>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="verification-dialog-title"
        aria-describedby="verification-dialog-description"
      >
        <DialogTitle id="verification-dialog-title">
          Konfirmasi Verifikasi
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            color="text.primary"
            id="verification-dialog-description"
          >
            Apakah Anda yakin ingin memverifikasi dokter ini?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Batal
          </Button>
          <Button onClick={verifHandler} variant="contained" color="primary">
            Verifikasi
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Pasien;
