import { Box, useTheme, Button } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";
import { read, utils, writeFile } from "xlsx";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
const Pasien = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const { user } = UserAuth();
  const [updated, setUpdated] = useState(false)
  const [page, setPage] = useState(0);

  const navigate = useNavigate();

  const listenPageChange = (page) => {
    setPage(page);
    localStorage.setItem("pasienPage", page);
  };

  const handleUploadFile = (e) => {
    const file = e.target.files[0];

    if (!file) {
      // Handle the case when the user cancels file selection
      console.log("File selection was canceled.");
      return;
    }

    console.log(file);

    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        const result = event.target.result;
        const wb = read(result, { type: "binary" });
        const ws = wb.Sheets[wb.SheetNames[0]];
        const data = utils.sheet_to_json(ws, { header: 1 });

        // Skip the first row by slicing the array
        const dataToProcess = data.slice(1);

        const fetchPromises = dataToProcess.map((row, index) => {
          if (row) {
            const fullName = row[0]?.trim();
            const patientType = row[1];
            const height = `${row[2]}`;
            const weight = `${row[3]}`;
            const residence = row[4];
            const placeOfBirth = row[5];
            const dateOfBirth = `${row[6]}`;
            const treatmentLocation = [row[7]];
            const parentPhoneNumber = `${row[8]}`;

            if (!fullName || isNaN(height) || isNaN(weight)) {
              // Handle invalid data here, and maybe log an error message
              console.error(`Invalid data in row ${index + 2}`);
              return null;
            }

            const payload = {
              fullName,
              patientType,
              height,
              weight,
              residence,
              placeOfBirth,
              dateOfBirth,
              treatmentLocation,
              parentPhoneNumber,
              addedById: "7ed91ec28dcb4f61ad6d4a2a6078cf15",
            };

            console.log(payload);

            return fetch("https://ashokanew-bjh4uafqtq-uc.a.run.app/pasien", {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
                Admin: "true",
              },
              body: JSON.stringify(payload),
            }).then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
            });
          }
        });

        Promise.all(fetchPromises.filter(Boolean))
          .then(() => {
            console.log("All fetch requests completed successfully.");
            alert("Success Import Data")
            setUpdated(!updated);
            resolve(result);
          })
          .catch((error) => {
            console.error("Error:", error);
            reject(error);
          });
      };

      reader.onerror = (err) => {
        reject(err);
      };

      reader.readAsBinaryString(file);
    });
  };

  useEffect(() => {
    const pasienPage = localStorage.getItem("pasienPage");
    console.log(pasienPage);
    if (pasienPage) {
      setPage(parseInt(pasienPage));
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      try {
        const response = await fetch(
          "https://ashokanew-bjh4uafqtq-uc.a.run.app/pasien?get_all=true",
          {
            headers: {
              Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
              Admin: "true",
            },
          }
        );
        const data = await response.json();
        console.log(data);
        list = data.pasien.map((item) => {
          let progress;
          switch (item.progress) {
            case 1:
              progress = "Pre Operasi";
              break;
            case 2:
              progress = "Intra Operasi";
              break;
            case 3:
              progress = "Pasca Operasi";
              break;
            case 4:
              progress = "Follow Up (0-3 Bulan)";
              break;
            case 5:
              progress = "Follow Up (3-12 Bulan)";
              break;
            case 6:
              progress = "Follow Up (>12 Bulan)";
              break;
            default:
              progress = item.progress;
          }

          const weight = item.weight + " kg";
          const height = item.height + " cm";

          return {
            userId: item.userId,
            ...item,
            progress,
            weight,
            height,
          };
        });
        setData(list);
        setLoading(false);
      } catch (err) {}
    };

    fetchData();
  }, [updated]);

  const handleDetailClick = (pasiennya) => {
    navigate("/pasien/" + pasiennya.userId);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    {
      field: "fullName",
      headerName: "Name",
      flex: 1,
    },
    {
      field: "parentPhoneNumber",
      headerName: "HP Ortu",
      flex: 1,
    },
    {
      field: "placeOfBirth",
      headerName: "Tempat Lahir",
      flex: 1,
    },
    {
      field: "dateOfBirth",
      headerName: "Tanggal Lahir",
      flex: 1,
    },
    {
      field: "weight",
      headerName: "Berat",
      flex: 1,
    },
    {
      field: "height",
      headerName: "Tinggi",
      flex: 1,
    },
    {
      field: "patientType",
      headerName: "Pasien Type",
      flex: 1,
    },
    {
      field: "progress",
      headerName: "Progress",
      flex: 1,
    },
    {
      field: "treatmentLocation",
      headerName: "Location",
      flex: 1,
    },

    {
      field: "Detail",
      headerName: "Detail",
      sortable: false,
      filterable: false,
      width: 120,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          startIcon={<ZoomInIcon />}
          onClick={() => {
            handleDetailClick(params.row);
          }}
        >
          Detail
        </Button>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="Pasien" subtitle="List Data Pasien" />
      <Box
        sx={{
          mt: 2,
          height: 600,
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            color: colors.blueAccent[900],
            backgroundColor: colors.primary[900],
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            backgroundColor: colors.primary[900],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[600],
            color: "#ffffff",
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[900],
            color: "#ffffff",
          },

          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[600],
          },
          "& .MuiTablePagination-root": {
            color: "#ffffff",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.redAccent[300]} !important`,
          },
          "& .MuiDataGrid-sortIcon": {
            color: "#ffffff",
          },
          "& .css-196n7va-MuiSvgIcon-root": {
            color: "#ffffff",
          },
        }}
      >
        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ mr: 2 }}>
            <a
              href="https://ashoka-dashboard.com/pasien.xlsx"
              download="pasien.xlsx"
            >
              <Button variant="contained">Format</Button>
            </a>
          </Stack>

          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <label htmlFor="excel-upload-input">
              <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleUploadFile}
                style={{ display: "none" }}
                id="excel-upload-input"
              />
              <Button variant="contained" component="span">
                Import Excel
              </Button>
            </label>
          </Stack>
        </Stack>
        {data.length > 0 && (
          <DataGrid
            pagination
            density="standard"
            autoHeight={false} // Disable autoHeight
            pageSize={pageSize}
            page={page}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onPageChange={(newPage) => listenPageChange(newPage)}
            rowsPerPageOptions={[10, 25, 100]}
            rows={data}
            columns={columns}
            components={{ Toolbar: CustomToolbar }}
            getRowId={(row) => row.userId}
          />
        )}
      </Box>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default Pasien;
