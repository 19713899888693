import { Box, useTheme, Button } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from '@mui/x-data-grid';
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useEffect, useState } from "react";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { UserAuth } from "../../context/AuthContext";
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}
const Pasien = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true)
  const { user } = UserAuth();
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      let list = [];
      try {
        const response = await fetch(
          "https://ashokanew-bjh4uafqtq-uc.a.run.app/user?field=userType&value=Dokter%20Spesialis%20Urologi",
          {
            headers: {
              Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
              Admin: "true",
            },
          }
        );
        const data = await response.json();

        setData(data.user);
        setLoading(false)
      } catch (err) {
      
      }
    };

    fetchData();
  }, []);

  const handleDetailClick = (pasiennya) => {
    navigate("/user/" + pasiennya.id);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "fullName",
      headerName: "Full Name",
      flex: 1,
    },
    {
      field: "npaIdi",
      headerName: "NPA IDI",
      flex: 1,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "residence",
      headerName: "Residence",
      flex: 1,
    },
    {
      field: "userType",
      headerName: "User Type",
      flex: 1,
    },
    {
      field: "verified",
      headerName: "Verified",
      flex: 1,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
    },
    {
      field: "Detail",
      headerName: "Detail",
      sortable: false,
      filterable: false,
      width: 120,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          startIcon={<ZoomInIcon />}
          onClick={() => {
            handleDetailClick(params.row);
          }}
        >
          Detail
        </Button>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header
        title="Dokter Spesialis"
        subtitle="List Data Dokter Spesialis Urology"
      />
       <Box
        sx={{
          mt: 2,
          height: 600,
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
            color: colors.blueAccent[900],
            backgroundColor: colors.primary[900],
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
            backgroundColor: colors.primary[900],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[600],
            color: "#ffffff",
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[900],
            color: "#ffffff",
          },

          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[600],
          },
          "& .MuiTablePagination-root": {
            color: "#ffffff",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.redAccent[300]} !important`,
          },
          "& .MuiDataGrid-sortIcon": {
            color: "#ffffff",
          },
          "& .css-196n7va-MuiSvgIcon-root": {
            color: "#ffffff",
          },
        }}
      >
        <DataGrid
          pagination
          density="standard"
          autoHeight={false} // Disable autoHeight
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[10, 25, 100]}
          rows={data}
          columns={columns}
          components={{ Toolbar: CustomToolbar }}
          getRowId={(row) => row.id}
        />
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default Pasien;
