import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import Grid from "@mui/material/Grid";
import StatBox from "../../components/StatBox";
import { useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import CollectionsIcon from "@mui/icons-material/Collections";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import PersonIcon from "@mui/icons-material/Person";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import { Chart, BarSeries, Title, ArgumentAxis, ValueAxis, Legend, Tooltip } from "@devexpress/dx-react-chart-material-ui";
import { Stack, Animation } from "@devexpress/dx-react-chart";
import { EventTracker } from "@devexpress/dx-react-chart";
import { UserAuth } from "../../context/AuthContext";
import axios from "axios";
const Root = (props) => (
  <Legend.Root {...props} sx={{ display: 'flex', margin: 'auto', flexDirection: 'row' }} />
);

const Labela = (props) => (
  <Legend.Label {...props} sx={{ whiteSpace: 'nowrap' }} />
);

const Dashboard = () => {
  const [jumlahPasien, setJumlahPasien] = useState(0);
  const [jumlahDokter, setJumlahDokter] = useState(0);
  const [jumlahHipospadia, setJumlahHipospadia] = useState(0);
  const [jumlahSirkumsisi, setJumlahSirkumsisi] = useState(0);
  const [jumlahData, setJumlahData] = useState(0);
  const [annotatedData, setAnnotatedData] = useState({});
  const [loading, setLoading] = useState(true);
  const { user } = UserAuth();



  useEffect(() => {
    console.log("test");
    const getCounts = async () => {
      try {
        const headers = {
          Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
          Admin: "true"
        };

        const response = await axios.get("https://ashokanew-bjh4uafqtq-uc.a.run.app/stats", {
          headers: headers
        });

        const data = response.data;

        if (!data.error) {
          const stats = data.stats;

          setJumlahPasien(stats.pasien.Total);
          setJumlahDokter(stats.user["Dokter Spesialis Urologi"] + stats.user["Dokter Umum"]);
          setJumlahHipospadia(stats.pasien.Hipospadia);
          setJumlahSirkumsisi(stats.pasien['Kelainan Genitalia']);
          setJumlahData(stats.stage.total['annotatedImages'] + stats.stage.total['unannotatedImages']); // Fix this line if needed
          setAnnotatedData(
            Object.entries(stats.stage)
              .map(([key, value]) => {
                let tipe = key.charAt(0).toUpperCase() + key.slice(1);
                if (tipe === "Total") return null; // ignore 'Total' object
                return {
                  tipe,
                  annotatedImages: value.annotatedImages,
                  unannotatedImages: value.unannotatedImages,
                };
              })
              .filter(Boolean)
          );

        }

        setLoading(false);
      } catch (err) {
        console.error(err);
      }
    };
    getCounts();
  }, []);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const boxStyles = {
    bgcolor: colors.primary[400],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: "35px",
    paddingBottom: "35px",
  };

  if (loading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="DASHBOARD" subtitle="Welcome to Ashoka Dashboard" />
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <Box sx={boxStyles}>
            <StatBox
              title={jumlahPasien}
              subtitle="Patient"
              progress="0.75"
              icon={
                <PersonIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box sx={boxStyles}>
            <StatBox
              title={jumlahDokter}
              subtitle="General and Specialist doctors"
              progress="0.50"
              icon={
                <MedicalInformationIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Box sx={boxStyles}>
            <StatBox
              title={jumlahData}
              subtitle="Pictures & Video"
              progress="0.30"
              icon={
                <CollectionsIcon
                  sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
                />
              }
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12} md={6}>
          <Chart
            data={[
              { tipe: "Hipospadia", jumlah: jumlahHipospadia },
              { tipe: "Sirkumsisi", jumlah: jumlahSirkumsisi },
            ]}
          >
            <ArgumentAxis />
            <ValueAxis />

            <BarSeries name="Number" valueField="jumlah" argumentField="tipe" color="#0237A1"/>
            <Title text="Patient" />
            <Animation />
            <EventTracker />
            <Legend
              position="bottom"
              rootComponent={Root}
              labelComponent={Labela}
            />
            <Tooltip />
            <Stack />
          </Chart>
        </Grid>
        <Grid item xs={12} md={6}>
          <Chart data={annotatedData}>
            <ArgumentAxis />
            <ValueAxis />

            <BarSeries
              name="Annotated"
              valueField="annotatedImages"
              argumentField="tipe"
              color="#0237A1"
            />
            <BarSeries
              name="Unannotated"
              valueField="unannotatedImages"
              argumentField="tipe"
              color="#c0c0c0"
            />
            <Animation />
            
            <Legend
              position="bottom"
              rootComponent={Root}
              labelComponent={Labela}
            />
            <Title text="Annotated & Unannotated Data" />
            <Stack />
          </Chart>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
