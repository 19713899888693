import { useState } from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import Header from "../../components/Header";
import { storage } from "../../firebase";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { MuiTelInput } from "mui-tel-input";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { UserAuth } from "../../context/AuthContext";

export default function AddUser() {
  const theme = useTheme();

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [fileSize, setFileSize] = useState(false)


  const { user } = UserAuth();
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("")

  const clearState = () => {
    setEmail("");
    setFullName("");
    setPhone("");
    setImage("");
    setPassword("")
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        setFileSize(true)
        return;
      }
      setImage(file);
    }
  };
  const phoneChange = (newPhone) => {
    setPhone(newPhone);
  };

  const defaultImageUrl = "https://via.placeholder.com/100x100";

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      !email ||
      !fullName ||
      !phone ||
      !image ||
      !password
    ) {
      setEmpty(true);
      return;
    }

    setEmpty(false);
    setFileSize(false);
    setLoading(true);
    try {
      const metadata = {
        contentType: "image/jpeg",
      };
      const newPhone = phone.replace(/[+ ]/g, "");
      const storageRef = ref(
        storage,
        "/webdashboard/profilepicture/" + newPhone + ".jpg"
      );
      console.log(image);

      uploadBytes(storageRef, image, metadata)
        .then((snapshot) => {
          return getDownloadURL(storageRef);
        })
        .then((url) => {
          const payload = {
            email: email,
            fullName: fullName,
            password: password,
            phoneNumber: newPhone,
            imageUrl: url,
           
          };
          console.log(payload)
          fetch("https://ashokanew-bjh4uafqtq-uc.a.run.app/admin", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
              Admin: "true",
            },
            body: JSON.stringify(payload),
          })
            .then((response) => {
              if (!response.ok) {
                setLoading(false)
                throw new Error("Network response was not ok");
              }
              return response.json();
            })
            .then((data) => {
              setSuccess(true);
              setLoading(false);
              clearState();
            });
        });
    } catch (error) {
      setLoading(false)
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  return (
    <Box m="20px">
      <Header
        title="Add Admin"
        subtitle="Isi Form untuk menambahkan Admin baru"
      />

      <ThemeProvider theme={theme}>
        <Container component="main">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              noValidate
              sx={{ mt: 2 }}

            >
              {empty && (
                <Alert sx={{ mb: 1 }} severity="warning">
                  Field Belum Lengkap!
                </Alert>
              )}
              {fileSize && (
                <Alert sx={{ mb: 1 }} severity="warning">
                  Image tidak boleh lebih dari 2MB!
                </Alert>
              )}
              {success && (
                <Alert sx={{ mb: 1 }} severity="success">
                  Berhasil Menambahkan Admin!
                </Alert>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth={true}
                    id="fullName"
                    label="Full Name"
                    name="fullName"
                    autoComplete="name"
                    value={fullName}
                    onChange={(event) => setFullName(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth={true}
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    type="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <label htmlFor="image">Upload Image</label>
                  <Input
                    required
                    fullWidth={true}
                    id="image"
                    type="file"
                    accept="image/*"
                    onChange={handleImageUpload}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <img
                    src={image ?  URL.createObjectURL(image) : defaultImageUrl}
                    alt="Preview"
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TextField
                    required
                    fullWidth={true}
                    id="password"
                    label="Password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <MuiTelInput
                    label="Nomor Telepon"
                    fullWidth
                    defaultCountry="ID"
                    value={phone}
                    onChange={phoneChange}
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={clearState}
                      sx={{ mt: 3, mb: 2, mr: 1 }}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Add User
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
