import { Box, IconButton, useTheme, Typography } from "@mui/material";
import { useContext} from "react";
import { useNavigate } from "react-router-dom";
import { ColorModeContext } from "../../theme";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { UserAuth } from "../../context/AuthContext"

const Topbar = () => {
  const { user, logout } = UserAuth();
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  const navigate = useNavigate()
  const logoutHandler = async() => {
    try {
      await logout();
      navigate('/');
    } catch (e) {
      console.log(e.message);
    }
  }

  return (
    <Box display="flex" justifyContent="right" p={2}>

      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>

        <IconButton aria-label="logout" onClick={logoutHandler}>
          <PersonOutlinedIcon />
          <Typography  variant="button" sx={{marginLeft: 1}}>Logout</Typography>
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
