import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref } from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: 'rscm-hypospadias.firebaseapp.com',
  projectId: 'rscm-hypospadias',
  storageBucket: 'rscm-hypospadias.appspot.com',
  messagingSenderId: '538227073512',
  appId: '1:538227073512:web:3b21458fb7512206e3045d',
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);
export const storageRef = ref(storage, '/ASHOKA');
