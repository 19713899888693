import { Box, useTheme, Grid, useMediaQuery, Typography } from "@mui/material";


import Carousel from "react-material-ui-carousel";

const ImageList = () => {
  const theme = useTheme();

  const dummyImages = [
    "https://via.placeholder.com/150",
    "https://via.placeholder.com/150",
    "https://via.placeholder.com/150",
    "https://via.placeholder.com/150",
    "https://via.placeholder.com/150",
    "https://via.placeholder.com/150",
    "https://via.placeholder.com/150",
    "https://via.placeholder.com/150",
    "https://via.placeholder.com/150",
    "https://via.placeholder.com/150",
  ];

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const items1 = [];

  for (let i = 0; i < dummyImages.length; i += (isMobile ? 1 : 4)) {
    items1.push(dummyImages.slice(i, i + (isMobile ? 1 : 4)));
  }

  const items2 = [];

  for (let i = 0; i < dummyImages.length; i += (isMobile ? 1 : 4)) {
    items2.push(dummyImages.slice(i, i + (isMobile ? 1 : 4)));
  }

  return (
    <Box m="20px">
      <Box mb="10px">
        <Typography variant="h3" gutterBottom sx={{ fontWeight: 700}}>
          Test 1
        </Typography>
        <Carousel
          autoPlay={false}
          navButtonsAlwaysVisible
          sx={{
            height: isMobile ? "300px" : "100%",
          }}
        >
          {items1.map((item, index) => (
            <CarouselItem key={index} item={item} />
          ))}
        </Carousel>
      </Box>
      <Box>
        <Typography variant="h3" gutterBottom sx={{ fontWeight: 700 }}>
          Test 2
        </Typography>
        <Carousel
          autoPlay={false}
          navButtonsAlwaysVisible
          sx={{
            height: isMobile ? "300px" : "100%",
          }}
        >
          {items2.map((item, index) => (
            <CarouselItem key={index} item={item} />
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

const CarouselItem = ({ item }) => {
  return (
    <Grid container spacing={2}>
      {item.map((img, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
        <img src={img} alt={`Dummy-${index}`} width="100%" height="auto" style={{ objectFit: 'fill' }} />
      </Grid>
      ))}
    </Grid>
  );
};

export default ImageList;
