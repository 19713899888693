import { Routes, Route, Navigate } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import Topbar from './scenes/global/Topbar';
import Sidebar from './scenes/global/Sidebar';
import Dashboard from './scenes/dashboard';
import DokterUmum from './scenes/dokterumum';
import Pasien from './scenes/pasien';
import DokterSpesialis from './scenes/dokterspesialis';
import OrangTua from './scenes/penggunaumum';
import Hipospadia from './scenes/hipospadia';
import PhotoEditor from './scenes/Editor';
import Login from './scenes/global/Login';
import Portofolio from './scenes/global/Portofolio';
import ForgotPass from "./scenes/global/ForgotPass"
import DetailPasien from './scenes/Details/Pasien';
import DetailUser from './scenes/Details/User';
import DetailOrangTua from './scenes/Details/Orangtua';
import AddUser from './scenes/Forms/AddUser';
import AddPasien from './scenes/Forms/AddPasien';
import AddAdmin from './scenes/Forms/AddAdmin';
import SendReminder from './scenes/Forms/SendReminder';
import SendMobile from './scenes/Forms/ReminderMobile';
import ChangePassword from "./scenes/Forms/ChangePassword"
import Verifikasi from './scenes/verifikasi';
import AsesmentPaper from './components/AsesmentPaper';
import { ColorModeContext, useMode } from './theme';
import { UserAuth } from './context/AuthContext';

function App() {
  const [theme, colorMode] = useMode();
  const { user, role, isLoading } = UserAuth();

  function RequireAuth({ children }) {
    return user ? children : <Navigate to="/login" />;
  }

  if (isLoading) return null;

  const isPortofolioPage = window.location.pathname.includes('/portofolio');

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {user && role && !isPortofolioPage && <Sidebar role={role} style={{ height: '100%' }} />}
          <main className="content">
            {user && role && !isPortofolioPage && <Topbar />}
            <Routes>
              <Route path="/">
                <Route path='portofolio' >
                  <Route
                    path=":idUser"
                    element={(
                      <Portofolio />
                    )}
                  />
                </Route>
                <Route path="login" element={<Login />} />
                <Route path="forgotpass" element={<ForgotPass />} />
                <Route
                  index
                  element={(
                    <RequireAuth>
                      <Dashboard />
                    </RequireAuth>
                  )}
                />
                <Route path="user">
                  <Route
                    path=":idUser"
                    element={(
                      <RequireAuth>
                        <DetailUser role={role} />
                      </RequireAuth>
                    )}
                  />
                </Route>


                <Route path="pasien">
                  <Route
                    path=":idPasien"
                    element={(
                      <RequireAuth>
                        <DetailPasien role={role} />
                      </RequireAuth>
                    )}
                  />
                  
                  <Route
                    index
                    element={(
                      <RequireAuth>
                        <Pasien />
                      </RequireAuth>
                    )}
                  />
                </Route>
                <Route path="umum">
                  <Route
                    index
                    element={(
                      <RequireAuth>
                        <DokterUmum />
                      </RequireAuth>
                    )}
                  />
                </Route>
                <Route path="orangtua">
                  <Route
                    path=":idUser"
                    element={(
                      <RequireAuth>
                        <DetailOrangTua role={role} />
                      </RequireAuth>
                    )}
                  />
                  <Route
                    index
                    element={(
                      <RequireAuth>
                        <OrangTua />
                      </RequireAuth>
                    )}
                  />
                </Route>
                <Route path="spesialis">
                  <Route
                    index
                    element={(
                      <RequireAuth>
                        <DokterSpesialis />
                      </RequireAuth>
                    )}
                  />
                </Route>
                <Route path="verifikasi">
                  <Route
                    index
                    element={(
                      <RequireAuth>
                        <Verifikasi />
                      </RequireAuth>
                    )}
                  />
                </Route>
                <Route path="adduser">
                  <Route
                    index
                    element={(
                      <RequireAuth>
                        <AddUser role={role} />
                      </RequireAuth>
                    )}
                  />
                </Route>
                <Route path="changepassword">
                  <Route
                    index
                    element={(
                      <RequireAuth>
                        <ChangePassword role={role} />
                      </RequireAuth>
                    )}
                  />
                </Route>
                <Route path="addpasien">
                  <Route
                    index
                    element={(
                      <RequireAuth>
                        <AddPasien role={role} />
                      </RequireAuth>
                    )}
                  />
                </Route>
                <Route path="addadmin">
                  <Route
                    index
                    element={(
                      <RequireAuth>
                        <AddAdmin />
                      </RequireAuth>
                    )}
                  />
                </Route>
                <Route path="hipospadia">
                  <Route
                    index
                    element={(
                      <RequireAuth>
                        <Hipospadia />
                      </RequireAuth>
                    )}
                  />
                </Route>
                <Route path="sendreminder">
                  <Route
                    index
                    element={(
                      <RequireAuth>
                        <SendReminder />
                      </RequireAuth>
                    )}
                  />
                </Route>
                <Route path="sendmobile">
                  <Route
                    index
                    element={(
                      <RequireAuth>
                        <SendMobile />
                      </RequireAuth>
                    )}
                  />
                </Route>
                <Route path="asesmentest">
                  <Route
                    index
                    element={(
                      <RequireAuth>
                        <AsesmentPaper />
                      </RequireAuth>
                    )}
                  />
                </Route>
                <Route path="photoeditor">
                  <Route
                    index
                    element={(
                      <RequireAuth>
                        <PhotoEditor  />
                      </RequireAuth>
                    )}
                  />
                </Route>

              </Route>
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
