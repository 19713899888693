import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useTheme, Autocomplete } from "@mui/material";
import Header from "../../components/Header";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { MuiTelInput } from "mui-tel-input";
import { UserAuth } from "../../context/AuthContext";

const allCitiesInIndonesia = [
  "Ambon",
  "Balikpapan",
  "Banda Aceh",
  "Bandar Lampung",
  "Bandung",
  "Banjarmasin",
  "Batam",
  "Bekasi",
  "Bengkulu",
  "Bogor",
  "Cilegon",
  "Cimahi",
  "Cirebon",
  "Denpasar",
  "Depok",
  "Gorontalo",
  "Jakarta",
  "Jambi",
  "Jayapura",
  "Kediri",
  "Kendari",
  "Kupang",
  "Lhokseumawe",
  "Madiun",
  "Magelang",
  "Makassar",
  "Malang",
  "Manado",
  "Mataram",
  "Medan",
  "Metro",
  "Mojokerto",
  "Padang",
  "Palangkaraya",
  "Palembang",
  "Palu",
  "Pangkalpinang",
  "Parepare",
  "Pekanbaru",
  "Pematangsiantar",
  "Pontianak",
  "Probolinggo",
  "Sabang",
  "Salatiga",
  "Samarinda",
  "Sawahlunto",
  "Semarang",
  "Serang",
  "Sibolga",
  "Singkawang",
  "Solok",
  "Sorong",
  "Subulussalam",
  "Sukabumi",
  "Surabaya",
  "Surakarta",
  "Tangerang",
  "Tangerang Selatan",
  "Tanjung Pinang",
  "Tanjungbalai",
  "Tarakan",
  "Tasikmalaya",
  "Tebingtinggi",
  "Tegal",
  "Ternate",
  "Tidore Kepulauan",
  "Tomohon",
  "Tual",
  "Yogyakarta",
];

export default function AddPasien(props) {
  const theme = useTheme();

  const [tipeUser, setTipeUser] = useState("Hipospadia");
  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [tempatLahir, setTempatLahir] = useState("");
  const [tanggalLahir, setTanggalLahir] = useState(null);
  const [empty, setEmpty] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [residence, setResidence] = useState("");
  const [collaborators, setCollaborators] = useState([]); // State for collaborators
  const [newCollaborator, setNewCollaborator] = useState(""); // State for new collaborator input

  const { user } = UserAuth();

  const selectChange = (event) => {
    setTipeUser(event.target.value);
  };

  const phoneChange = (newPhone) => {
    setPhone(newPhone);
  };

  const clearHandle = () => {
    setFirstName("");
    setLastName("");
    setHeight("");
    setWeight("");
    setPhone("");
    setTempatLahir("");
    setTanggalLahir("");
    setResidence("");
    setCollaborators([]);
  };

  const handleAddCollaborator = () => {
    if (newCollaborator.trim() !== "") {
      if (collaborators.length < 3) {
        setCollaborators([...collaborators, newCollaborator.trim()]);
        setNewCollaborator("");
      }
    }
  };

  const handleNewCollaboratorChange = (e) => {
    setNewCollaborator(e.target.value);

    if (e.target.value.trim() !== "" && /,$/.test(e.target.value)) {
      handleAddCollaborator();
    }
  };

  const handleRemoveCollaborator = (index) => {
    const newCollaborators = collaborators.filter((_, i) => i !== index);
    setCollaborators(newCollaborators);
  };
  const handleNewCollaboratorBlur = () => {
    handleAddCollaborator();
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (
      !firstName ||
      !lastName ||
      !phone ||
      !tipeUser ||
      !props.role ||
      !residence ||
      collaborators.length === 0
    ) {
      setEmpty(true);
      return;
    }
    setEmpty(false);
    setLoading(true);

    const payload = {
      dateOfBirth: tanggalLahir,
      fullName: (firstName + " " + lastName).trim(),
      parentPhoneNumber: phone.replace(/[+ ]/g, ""),
      patientType: tipeUser,
      placeOfBirth: tempatLahir,
      residence: residence,
      addedById: "7ed91ec28dcb4f61ad6d4a2a6078cf15",
      height: height,
      weight: weight,
      treatmentLocation: collaborators,
    };

    console.log(payload)

    fetch("https://ashokanew-bjh4uafqtq-uc.a.run.app/pasien", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
        Admin: "true",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        setSuccess(true);
        setLoading(false);
        clearHandle();
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoading(false)
      });
  };

  return (
    <Box m="20px">
      <Header
        title="Add Pasien"
        subtitle="Isi Form untuk menambahkan pasien baru"
      />

      <ThemeProvider theme={theme}>
        <Container component="main">
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box component="form" noValidate sx={{ mt: 2, p: 1 }}>
              {empty && (
                <Alert sx={{ mb: 1 }} severity="warning">
                  Field Belum Lengkap!
                </Alert>
              )}
              {success && (
                <Alert sx={{ mb: 1 }} severity="success">
                  Berhasil Menambahkan Pasien!
                </Alert>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="firstName"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    autoComplete="given-name"
                    name="height"
                    required
                    fullWidth
                    id="height"
                    label="Tinggi Badan"
                    autoFocus
                    value={height}
                    onChange={(event) => setHeight(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="weight"
                    label="Berat Badan"
                    name="weight"
                    autoComplete="family-name"
                    value={weight}
                    onChange={(event) => setWeight(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="tipeUser">Tipe Pasien</InputLabel>
                    <Select
                      labelId="tipeUser"
                      id="demo-simple-select"
                      value={tipeUser}
                      label="Tipe User"
                      onChange={selectChange}
                    >
                      <MenuItem value={"Hipospadia"}>Hipospadia</MenuItem>
                      <MenuItem value={"Kelainan Genitalia"}>Kelainan Genitalia</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    id="domisili"
                    options={allCitiesInIndonesia}
                    value={residence}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(event, newValue) => setResidence(newValue)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Domisili"
                        variant="outlined"
                        fullWidth
                        required
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    id="tempatLahir"
                    label="Tempat Lahir"
                    name="tempatLahir"
                    autoComplete="tempatLahir"
                    value={tempatLahir}
                    onChange={(event) => setTempatLahir(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        onChange={(newValue) => {
                          console.log(newValue.format("DD/MM/YYYY"));
                          setTanggalLahir(newValue.format("DD/MM/YYYY"));
                        }}
                        renderInput={(params) => <TextField {...params} />}
                        format="DD/MM/YYYY"
                        label="Tanggal Lahir"
                      />
                    </LocalizationProvider>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <div>
                    {collaborators.map((collaborator, index) => (
                      <Chip
                        key={index}
                        label={collaborator}
                        onDelete={() => handleRemoveCollaborator(index)}
                        sx={{ m: 1 }}
                      />
                    ))}
                  </div>
                  <TextField
                    sx={{ mt: 2 }}
                    label="Add Rumah Sakit"
                    fullWidth
                    value={newCollaborator}
                    onChange={handleNewCollaboratorChange}
                    placeholder="Maksimal 3, Pisah dengan koma"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleAddCollaborator();
                      }
                    }}
                    onBlur={handleNewCollaboratorBlur}
                    disabled={collaborators.length >= 3} // Disable the TextField when the limit is reached
                  />
                </Grid>
                <Grid item xs={12}>
                  <MuiTelInput
                    label="Nomor Orang Tua"
                    fullWidth
                    defaultCountry="ID"
                    value={phone}
                    onChange={phoneChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={clearHandle}
                      sx={{ mt: 3, mb: 2, mr: 1 }}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Add Pasien
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
