import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { saveAs } from 'file-saver';
import Select from "@mui/material/Select";
import {
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import Header from "../../components/Header";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import { UserAuth } from "../../context/AuthContext";
import Alert from "@mui/material/Alert";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
import {
  Chart,
  BarSeries,
  PieSeries,
  Title,
  ArgumentAxis,
  ValueAxis,
  Legend,
  Tooltip,
} from "@devexpress/dx-react-chart-material-ui";
import { Stack, Animation } from "@devexpress/dx-react-chart";
import { EventTracker } from "@devexpress/dx-react-chart";
import Portofolio from "../global/Portofolio";

const Root = (props) => (
  <Legend.Root
    {...props}
    sx={{ display: "flex", margin: "auto", flexDirection: "row" }}
  />
);

const Labela = (props) => (
  <Legend.Label {...props} sx={{ whiteSpace: "nowrap" }} />
);

export default function Details(props) {
  const theme = useTheme();
  const params = useParams();
  const { user } = UserAuth();
  const navigate = useNavigate();

  const [data, setData] = useState({});
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [npaIdi, setNpa] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [residence, setResidence] = useState("");
  const [userType, setUserType] = useState("");
  const [verified, setVerified] = useState("");
  const [practiceLocation, setPracticeLocation] = useState([]);
  const [firstPractice, setFirstPractice] = useState("");
  const [editDialog, setEditDialog] = useState(false);
  const [hapusDialog, sethapusDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [operationTechniques, setOperationTechniques] = useState([]);
  const [patientCounts, setPatientCounts] = useState({});
  const [penisShape, setPenisShapes] = useState([]);
  const [meatusLocation, setMeatusLocations] = useState([]);

  const handlePortofolioClick = () => {
    let domElement = document.getElementById("porto");
    htmlToImage
      .toPng(domElement)
      .then(function (dataUrl) {
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: "a4",
        });
        pdf.addImage(dataUrl, "PNG", 7, 5, 200, 280);
        const pdfBlob = pdf.output("blob");
  
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(pdfBlob);
        downloadLink.download = `${params.idUser}.pdf`;
        downloadLink.click();
  
        const formData = new FormData();
        formData.append("file", pdfBlob, `${params.idUser}.pdf}`);
        formData.append("userId", params.idUser); 
  
        fetch("https://ashokanew-bjh4uafqtq-uc.a.run.app/upload", {
          method: "POST",
          body: formData,
          headers: { Authorization: `Bearer ${user.stsTokenManager.accessToken}`, Admin: "true"},

        })
          .then((response) => response.json())
          .then((data) => {
            console.log("File uploaded successfully to the server", data);
          })
          .catch((error) => {
            console.error("Error uploading file to the server", error);
          });
      })
      .catch(function (error) {
        console.error("Oops, something went wrong!", error);
      });
  };
  
  

  const handleEditClick = () => {
    setEditDialog(true);
  };

  const handleHapusClick = () => {
    sethapusDialog(true);
  };

  const handleDialogClose = () => {
    setEditDialog(false);
  };

  const handleHapusDialog = () => {
    sethapusDialog(false);
  };

  const deleteHandler = () => {
    sethapusDialog(false);
    setLoading(true);
    fetch("https://ashokanew-bjh4uafqtq-uc.a.run.app/user/" + params.idUser, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
        Admin: "true",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        navigate("/");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const editHandler = () => {
    setEditDialog(false);
    setLoading(true);
    const updatedData = {
      email: email,
      fullName: fullName,
      residence: residence,
      id: params.idUser,
      phoneNumber: phoneNumber,
      imageUrl: imageUrl,
      userType: userType,
      npaIdi: npaIdi,
      practiceLocation: practiceLocation,
    };

    fetch("https://ashokanew-bjh4uafqtq-uc.a.run.app/user", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
        Admin: "true",
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => response.json())
      .then((data) => {
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://ashokanew-bjh4uafqtq-uc.a.run.app/user/" + params.idUser,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
              Admin: "true",
            },
          }
        );
        const datanya = await response.json();

        if (!datanya.error && datanya.user) {
          setData(datanya.user);
          setEmail(datanya.user.email);
          setFullName(datanya.user.fullName);
          setImageUrl(datanya.user.imageUrl);
          setNpa(datanya.user.npaIdi);
          setPhoneNumber(datanya.user.phoneNumber);
          setResidence(datanya.user.residence);
          setUserType(datanya.user.userType);
          setVerified(datanya.user.verified.toLowerCase());
          setPracticeLocation(datanya.user.practiceLocation);
          setFirstPractice(datanya.user.practiceLocation[0]);
        }
      } catch (e) {
        console.log(e);
      }
    };

    const fetchHistory = async () => {
      try {
        const response = await fetch(
          "https://ashokanew-bjh4uafqtq-uc.a.run.app/history?field=idDoctor&value=" +
            params.idUser,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
              Admin: "true",
            },
          }
        );
        const datanya = await response.json();

        const operationTechniqueCounts = {};
        const uniquePatients = {};
        const uniqueIdStages = {};
        const penisShapes = {}; // Object to store penisShape values
        const meatusLocations = {}; // Object to store meatusLocation values

        const patientCounts = {
          Hipospadia: 0,
          Sirkumsisi: 0,
        };

        datanya.history.forEach((item) => {
          const idStage = item.idStage;
          const operationTechnique = item.annotatedData.operationTechnique;
          const idPatient = item.idPatient;
          const penisShape = item.annotatedData.penisShape;
          const meatusLocation = item.annotatedData.meatusLocation;

          if (idPatient) {
            // Check if the patient is already counted
            if (!uniquePatients[idPatient]) {
              uniquePatients[idPatient] = true;

              // Increment counts based on the type
              if (idPatient.includes("Hipospadia")) {
                patientCounts.Hipospadia++;
              } else if (idPatient.includes("Sirkumsisi")) {
                patientCounts.Sirkumsisi++;
              }
            }
          }

          if (!uniqueIdStages[idStage] && operationTechnique) {
            uniqueIdStages[idStage] = true;

            if (!operationTechniqueCounts[operationTechnique]) {
              operationTechniqueCounts[operationTechnique] = 1;
            } else {
              operationTechniqueCounts[operationTechnique]++;
            }
          }

          if (
            penisShape !== null &&
            penisShape !== undefined &&
            penisShape !== ""
          ) {
            if (!penisShapes[penisShape]) {
              penisShapes[penisShape] = 1;
            } else {
              penisShapes[penisShape]++;
            }
          }

          
          if (
            meatusLocation !== null &&
            meatusLocation !== undefined &&
            meatusLocation !== ""
          ) {
            if (!meatusLocations[meatusLocation]) {
              meatusLocations[meatusLocation] = 1;
            } else {
              meatusLocations[meatusLocation]++;
            }
          }
        });

        const operationTechniques = Object.entries(
          operationTechniqueCounts
        ).map(([operationTechnique, count]) => ({
          tipe: operationTechnique,
          jumlah: count,
        }));

        const formattedPenisShapes = Object.entries(penisShapes)
          .filter(
            ([tipe, jumlah]) =>
              tipe !== "" && tipe !== undefined && jumlah !== undefined
          )
          .map(([tipe, jumlah]) => ({
            tipe,
            jumlah,
          }));

        const formattedMeatusLocations = Object.entries(meatusLocations)
          .filter(
            ([tipe, jumlah]) =>
              tipe !== "" && tipe !== undefined && jumlah !== undefined
          )
          .map(([tipe, jumlah]) => ({
            tipe,
            jumlah,
          }));

        console.log("Operation Techniques:", operationTechniques);
        console.log("Patient Counts:", patientCounts);
        console.log("Penis Shapes:", formattedPenisShapes);
        console.log("Meatus Locations:", formattedMeatusLocations);

        setPatientCounts(patientCounts);
        setOperationTechniques(operationTechniques);
        setPenisShapes(formattedPenisShapes);
        setMeatusLocations(formattedMeatusLocations); 
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
    fetchHistory();
  }, [params.idUser]);

  if (!data || Object.keys(data).length === 0) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Box m="15px">
      <Header title="Detail Data User" />

      {data && (
        <ThemeProvider theme={theme}>
          <Container component="main">
            <CssBaseline />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box component="form" noValidate sx={{ mt: 2, p: 1 }}>
                {success && (
                  <Alert sx={{ mb: 1 }} severity="success">
                    Berhasil Mengubah User!
                  </Alert>
                )}
                <Grid container spacing={2}>
                  <Grid item sm={6}>
                    <TextField
                      size="small"
                      autoComplete="fullName"
                      name="fullName"
                      required
                      fullWidth
                      id="fullName"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            Nama Lengkap:
                          </InputAdornment>
                        ),
                      }}
                      value={fullName}
                      onChange={(e) => {
                        setFullName(e.target.value);
                        console.log(e.target.value);
                      }}
                      sx={{ input: { textAlign: "end" } }}
                    />
                  </Grid>
                  <Grid item sm={6}>
                    <Box display="flex" justifyContent="end" alignItems="end">
                      <Button
                        onClick={handlePortofolioClick}
                        variant="contained"
                        sx={{ mr: 1 }}
                      >
                        Download
                      </Button>
                      <Button
                        onClick={handleEditClick}
                        variant="contained"
                        sx={{ mr: 1 }}
                      >
                        Edit
                      </Button>
                      {props.role === "superadmin" && (
                        <Button
                          onClick={handleHapusClick}
                          variant="contained"
                          color="error"
                        >
                          Delete
                        </Button>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      size="small"
                      autoComplete="email"
                      name="email"
                      required
                      fullWidth
                      id="email"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            Email:
                          </InputAdornment>
                        ),
                      }}
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      sx={{ input: { textAlign: "end" } }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="tipeUser">Tipe User</InputLabel>
                      <Select
                        labelId="tipeUser"
                        id="demo-simple-select"
                        value={userType}
                        label="Tipe User"
                        onChange={(e) => {
                          setUserType(e.target.value);
                        }}
                      >
                        <MenuItem value={"Pengguna Umum"}>
                          Pengguna Umum
                        </MenuItem>
                        <MenuItem value={"Dokter Umum"}>Dokter Umum</MenuItem>
                        <MenuItem value={"Dokter Spesialis Urologi"}>
                          Dokter Spesialis Urologi
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="verified">Verified: </InputLabel>
                      <Select
                        labelId="verified"
                        id="verif"
                        value={verified}
                        label="Verified"
                        onChange={(e) => {
                          setVerified(e.target.value);
                        }}
                      >
                        <MenuItem value={"true"}>True</MenuItem>
                        <MenuItem value={"false"}>False</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      size="small"
                      autoComplete="Kontak"
                      name="Kontak"
                      required
                      fullWidth
                      id="Kontak"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            Kontak:
                          </InputAdornment>
                        ),
                      }}
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                      sx={{ input: { textAlign: "end" } }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl fullWidth size="small">
                      <InputLabel id="plate-1">Practice Location</InputLabel>
                      <Select
                        labelId="urethal"
                        id="urethal-select"
                        label="Urethal Plate"
                        value={firstPractice}
                        onChange={(event) =>
                          setFirstPractice(event.target.value)
                        }
                      >
                        {data.practiceLocation.map((key) => (
                          <MenuItem value={key} key={key}>
                            {key}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      size="small"
                      autoComplete="npa"
                      name="npa"
                      required
                      fullWidth
                      id="npa"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">NPA:</InputAdornment>
                        ),
                      }}
                      value={npaIdi}
                      onChange={(e) => {
                        setNpa(e.target.value);
                        console.log(e.target.value);
                      }}
                      sx={{ input: { textAlign: "end" } }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} sx={{ mb: 2 }}>
                    <TextField
                      size="small"
                      autoComplete="residence"
                      name="residence"
                      required
                      fullWidth
                      id="residence"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            Domisili:
                          </InputAdornment>
                        ),
                      }}
                      value={residence}
                      onChange={(e) => {
                        setResidence(e.target.value);
                      }}
                      sx={{ input: { textAlign: "end" } }}
                    />
                  </Grid>
                </Grid>
              </Box>

              <div style={{width: 1, height: 1, overflow: "hidden"}}>
              {data.userType === "Dokter Spesialis Urologi" && <Portofolio idUser={params.idUser} />}
              </div>
            </Box>
          </Container>
        </ThemeProvider>
      )}
      <Dialog
        open={editDialog}
        onClose={handleDialogClose}
        aria-labelledby="verification-dialog-title"
        aria-describedby="verification-dialog-description"
      >
        <DialogTitle id="verification-dialog-title">
          Konfirmasi Edit
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            color="text.primary"
            id="verification-dialog-description"
          >
            Apakah Anda yakin ingin mengedit data ini?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Batal
          </Button>
          <Button onClick={editHandler} variant="contained" color="primary">
            Edit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={hapusDialog}
        onClose={handleHapusDialog}
        aria-labelledby="verification-dialog-title"
        aria-describedby="verification-dialog-description"
      >
        <DialogTitle id="verification-dialog-title">
          Konfirmasi Hapus
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            color="text.primary"
            id="verification-dialog-description"
          >
            Apakah Anda yakin ingin menghapus data ini?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleHapusDialog} color="primary">
            Batal
          </Button>
          <Button onClick={deleteHandler} variant="contained" color="error">
            Hapus
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
