import { useState } from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import Header from "../../components/Header";
import { storage } from "../../firebase";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { MuiTelInput } from "mui-tel-input";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { UserAuth } from "../../context/AuthContext";

export default function AddUser() {
  const theme = useTheme();
  const [image, setImage] = useState("");
  const [tipeUser, setTipeUser] = useState("Pengguna Umum");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [npa, setNpa] = useState("");
  const [alamat, setAlamat] = useState("");
  const [phone, setPhone] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [fileSize, setFileSize] = useState(false)
  const [collaborators, setCollaborators] = useState([]); // State for collaborators
  const [newCollaborator, setNewCollaborator] = useState(""); // State for new collaborator input
  const { user } = UserAuth();

  const clearState = () => {
    setEmail("");
    setFullName("");
    setNpa("");
    setPhone("");
    setAlamat("");
    setTipeUser("");
    setImage("");
    setCollaborators([]);
  };


  const handleAddCollaborator = () => {
    if (newCollaborator.trim() !== "") {
      if (collaborators.length < 3) {
        setCollaborators([...collaborators, newCollaborator.trim()]);
        setNewCollaborator("");
      }
    }
  };

  const handleNewCollaboratorChange = (e) => {
    setNewCollaborator(e.target.value);

    if (e.target.value.trim() !== "" && /,$/.test(e.target.value)) {
      handleAddCollaborator();
    }
  };

  const handleRemoveCollaborator = (index) => {
    const newCollaborators = collaborators.filter((_, i) => i !== index);
    setCollaborators(newCollaborators);
  };
  const handleNewCollaboratorBlur = () => {
    handleAddCollaborator();
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if(file.type !== "image/jpeg" && file.type !== "image/png" && file.type !== "image/jpg"){
      console.log("file type not supported")
      return
    }
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        setFileSize(true)
        return;
      }
      setImage(file);
    }
  };
  const phoneChange = (newPhone) => {
    setPhone(newPhone);
  };
  const handleChange = (event) => {
    setTipeUser(event.target.value);
  };
  const defaultImageUrl = "https://via.placeholder.com/100x100";

  const handleSubmit = (event) => {
    event.preventDefault();
    setEmpty(false)
    setSuccess(false)

    if (
      !email ||
      !fullName ||
      !phone ||
      !alamat ||
      !tipeUser ||
      !image 
    ) {
      setEmpty(true);
      return;
    }

    setEmpty(false);
    setFileSize(false);
    setLoading(true);
    try {
      const metadata = {
        contentType: "image/jpeg",
      };
      const newPhone = phone.replace(/[+ ]/g, "");
      const storageRef = ref(
        storage,
        "/webdashboard/profilepicture/" + newPhone + ".jpg"
      );
      console.log(image);

      uploadBytes(storageRef, image, metadata)
        .then((snapshot) => {
          return getDownloadURL(storageRef);
        })
        .then((url) => {
          const payload = {
            email: email,
            fullName: fullName,
            npaIdi: npa,
            phoneNumber: newPhone,
            residence: alamat,
            userType: tipeUser,
            imageUrl: url,
            practiceLocation : collaborators
          };
          fetch("https://ashokanew-bjh4uafqtq-uc.a.run.app/user", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
              Admin: "true",

            },
            body: JSON.stringify(payload),
          })
            .then((response) => {
              if (!response.ok) {
                throw new Error("Network response was not ok");
              }
              return response.json();
            })
            .then((data) => {
              setSuccess(true);
              setLoading(false);
              clearState();
            });
        });
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  return (
    <Box m="20px">
      <Header
        title="Add User"
        subtitle="Isi Form untuk menambahkan User baru"
      />

      <ThemeProvider theme={theme}>
        <Container component="main">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              component="form"
              noValidate
              sx={{ mt: 2 }}
            >
              {empty && (
                <Alert sx={{ mb: 1 }} severity="warning">
                  Field Belum Lengkap!
                </Alert>
              )}
              {fileSize && (
                <Alert sx={{ mb: 1 }} severity="warning">
                  Image tidak boleh lebih dari 2MB!
                </Alert>
              )}
              {success && (
                <Alert sx={{ mb: 1 }} severity="success">
                  Berhasil Menambahkan User!
                </Alert>
              )}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth={true}
                    id="fullName"
                    label="Full Name"
                    name="fullName"
                    autoComplete="name"
                    value={fullName}
                    onChange={(event) => setFullName(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth={true}
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    type="email"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <label htmlFor="image">Upload Image</label>
                  <Input
                    required
                    fullWidth={true}
                    id="image"
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={handleImageUpload}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <img
                    src={image ?  URL.createObjectURL(image) : defaultImageUrl}
                    alt="Preview"
                    style={{ maxWidth: "100px", maxHeight: "100px" }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth={true}
                    id="npaIdi"
                    label="NPA IDI"
                    name="npaIdi"
                    autoComplete="npaIdi"
                    value={npa}
                    onChange={(event) => setNpa(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MuiTelInput
                    label="Nomor Telepon"
                    fullWidth
                    defaultCountry="ID"
                    value={phone}
                    onChange={phoneChange}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth={true}
                    id="alamat"
                    label="Alamat"
                    name="alamat"
                    autoComplete="address"
                    value={alamat}
                    onChange={(event) => setAlamat(event.target.value)}
                  />
                </Grid>
                
                <Grid item xs={12}>
                  <FormControl fullWidth={true}>
                    <InputLabel id="userType">User Type</InputLabel>
                    <Select
                      labelId="userType"
                      id="demo-simple-select"
                      value={tipeUser}
                      label="User Type"
                      onChange={handleChange}
                    >
                      <MenuItem value={"Pengguna Umum"}>Pengguna Umum</MenuItem>
                      <MenuItem value={"Dokter Umum"}>Dokter Umum</MenuItem>
                      <MenuItem value={"Dokter Spesialis Urologi"}>
                        Dokter Spesialis Urologi
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div>
                    {collaborators.map((collaborator, index) => (
                      <Chip
                        key={index}
                        label={collaborator}
                        onDelete={() => handleRemoveCollaborator(index)}
                        sx={{ m: 1 }}
                      />
                    ))}
                  </div>
                  <TextField
                    sx={{ mt: 2 }}
                    label="Add Rumah Sakit"
                    fullWidth
                    value={newCollaborator}
                    onChange={handleNewCollaboratorChange}
                    placeholder="Maksimal 3, Pisah dengan koma"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleAddCollaborator();
                      }
                    }}
                    onBlur={handleNewCollaboratorBlur}
                    disabled={collaborators.length >= 3} // Disable the TextField when the limit is reached
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="center" alignItems="center">
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={clearState}
                      sx={{ mt: 3, mb: 2, mr: 1 }}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="contained"
                      onClick={handleSubmit}
                      sx={{ mt: 3, mb: 2 }}
                    >
                      Add User
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
