import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useTheme } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import AtasPaper from "./AtasPaper";
import BawahPaper from "./BawahPaper";
import SampingPaper from "./SampingPaper";
import VideoPaper from "./VideoPaper";

import Alert from "@mui/material/Alert";
import UploadFile from "./UploadFile";

const Header = ({ data, category, pasien, upload = false }) => {
  const theme = useTheme();

  if (!data || data.length <= 0) {
    return <Alert severity="error">No Data Found</Alert>;
  }

  return (
    <Carousel
      autoPlay={false}
      navButtonsAlwaysVisible={false}
      swipe={false}
      NextIcon={<KeyboardArrowRightIcon />}
      PrevIcon={<KeyboardArrowLeftIcon />}
      sx={{ width: "100%"}}
    >
      {data
        .map((item, index) => {
          if (upload === false) {
            if (category === 1) {
              return <SampingPaper data={item} key={index} pasien={pasien}></SampingPaper>;
            } else if (category === 2) {
              return <BawahPaper data={item} key={index} pasien={pasien}></BawahPaper>;
            } else if (category === 3) {
              return <AtasPaper data={item} key={index} pasien={pasien}></AtasPaper>;
            } else if (category === 4) {
              return <VideoPaper data={item} key={index} pasien={pasien}></VideoPaper>;
            }
          } else {
            
              return <UploadFile data={item} key={index} pasien={pasien}></UploadFile>;
            
          }
          
        })}
    </Carousel>
  ) 
};

export default Header;
