import {
  TextField,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { useEffect, useState } from "react";
import { UserAuth } from "../context/AuthContext";
import { getEditorDefaults } from "@pqina/pintura";
import busur from "../images/busur.png";
import busurwarna from "../images/busurwarna.png";
import { PinturaEditor } from "@pqina/react-pintura";

const Header = ({ data, pasien }) => {
  const { user } = UserAuth();

  const [valid, setValid] = useState(data.validity !== undefined ? data.validity : "");
  const [bentuk, setBentuk] = useState(data.penisShape || "");
  const [chordee, setChordee] = useState(data.chordee || "");
  const [status, setStatus] = useState("");
  const [fistel, setFistel] = useState(data.fistel || "");
  const [dehiscence, setDehiscence] = useState(data.dehiscence || "");
  const [teknikOperasi, setTeknikOperasi] = useState(data.operationTechnique || "");

  const bentukPenisMap = {
    Normal: 1,
    Hipospadia: 2,
    Epispadia: 3,
    "Webbed Penis": 4,
    "Buried Penis": 5,
    Chordee: 6,
    "Ambiguous Genitalia": 7,
    Lainnya: 8,
  };

  const chordeeMapHipos = {
    "<15 derajat": 1,
    "15-30 derajat": 2,
    ">30 derajat": 3,
  };

  const chordeeMap = {
    "Tidak ada": 1,
    Mild: 2,
    Moderate: 3,
    Severe: 4,
  };

  const validityMap = [true, false]
  const fistelMap = {
    Ya: 1,
    Tidak: 0,
  };

  const teknikOperasiMap = {
    "Tubularized Incised Plate": 1,
    "Dorsal Inlay Graft": 2,
    "Dorsal Onlay Graft": 3,
    "Mathieu Urethroplasty": 4,
    "Two-Staged Urethroplasty": 5,
    "Re-do Urethroplasty": 6,
    "EPA Urethroplasty": 7,
    "Lainnya": 8,
  };


  const handleDelete = () => {
    console.log(data.id)
    fetch("https://ashokanew-bjh4uafqtq-uc.a.run.app/stage/"+data.id, {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${user.stsTokenManager.accessToken}`,
        "Admin": "true",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setStatus("success");
      })
      .catch((error) => {
        setStatus("error");
      });
  };

  const handleUpdate = async () => {
    setStatus("");
    const updatedData = {
      chordee: chordee,
      penisShape: bentuk,
      validity: valid,
      id: data.id,
      idDoctor: "7ed91ec28dcb4f61ad6d4a2a6078cf15"
    };

    if (teknikOperasi !== "") {
      updatedData.operationTechnique = teknikOperasi;
    }

    if (fistel !== "") {
      updatedData.fistel = fistel;
    }

    if (dehiscence !== "") {
      updatedData.dehiscence = dehiscence;
    }


    try {
      const response = await fetch(
        "https://ashokanew-bjh4uafqtq-uc.a.run.app/stage",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
            Admin: "true",
          },
          body: JSON.stringify(updatedData),
        }
      );
      const result = await response.json();
      setStatus("success");
    } catch (error) {
      setStatus("error");
    }
  };

  return (
    <>
      <Box
        m="5px"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Paper sx={{ p: 1, width: "100%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {status && (
                <Alert sx={{ mb: 1 }} severity={status}>
                  {status === "success" ? "Update Success!" : "Update Gagal!"}
                </Alert>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                mt: 2,
                height: 600,
              }}
            >
              <PinturaEditor
                {...getEditorDefaults()}
                src={data.fileUrl}
                enableButtonExport={false}
                util={"sticker"}
                enableUtils={false}
                enableToolbar={false}
                stickers={[busur, busurwarna]}
              />
            </Grid>
            
            <Grid item xs={12}>
              <FormControl fullWidth sx={{ mt: 1 }}>
                <InputLabel id="validity-1">Validity</InputLabel>
                <Select
                  labelId="glans"
                  id={"validity-select" + data.imageUrl}
                  label="Validity"
                  value={valid}
                  onChange={(event) => setValid(event.target.value)}
                  name="validity"
                >
                  <MenuItem name={`validity1`} value={true} key={`validity1`}>
                      true
                    </MenuItem>
                    <MenuItem name={`validity2`} value={false} key={`validity2`}>
                      false
                    </MenuItem>
                    
                </Select>
              </FormControl>
            </Grid>

            

            

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="bentukpenis-1">Bentuk Penis</InputLabel>
                <Select
                  labelId="meatus"
                  id={"bentuk-select" + data.imageUrl}
                  label="Bentuk penis"
                  value={bentuk}
                  onChange={(event) => setBentuk(event.target.value)}
                  name="meatus"
                >
                  {Object.keys(bentukPenisMap).map((key) => (
                    <MenuItem name={key} value={key} key={bentukPenisMap[key]}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="chordee-1">Chordee</InputLabel>
                <Select
                  labelId="urethalplate"
                  id={"chordee-select" + data.imageUrl}
                  label="Chordee"
                  value={chordee}
                  onChange={(event) => setChordee(event.target.value)}
                  name="urethal"
                >
                  {Object.keys(
                    pasien.patientType === "Hipospadia"
                      ? chordeeMapHipos
                      : chordeeMap
                  ).map((key) => (
                    <MenuItem
                      name={key}
                      value={key}
                      key={
                        pasien.patientType === "Hipospadia"
                          ? chordeeMapHipos[key]
                          : chordeeMap[key]
                      }
                    >
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {data.progress === 2 ? (
             <Grid item xs={12}>
             <FormControl fullWidth>
               <InputLabel id="teknikOperasi-1">Teknik Operasi</InputLabel>
               <Select
                 labelId="teknikOperasi"
                 id={"bentuk-select" + data.imageUrl}
                 label="Teknik Operasi"
                 value={teknikOperasi}
                 onChange={(event) => setTeknikOperasi(event.target.value)}
                 name="teknikOperasi"
               >
                 {Object.keys(teknikOperasiMap).map((key) => (
                   <MenuItem name={key} value={key} key={teknikOperasiMap[key]}>
                     {key}
                   </MenuItem>
                 ))}
               </Select>
             </FormControl>
           </Grid>
            ) : (
              ""
            )}

            {data.progress >= 4 ? (
              <Grid item xs={12}>
                <TextField
                  label="Fistel"
                  name="fistel"
                  fullWidth
                  id="fistel"
                  value={fistel}
                  onChange={(event) => setFistel(event.target.value)}
                />
              </Grid>
            ) : (
              ""
            )}

            {data.progress >= 4 ? (
              <Grid item xs={12}>
                <TextField
                  label="Wound Dehiscence"
                  name="placeOfBirth"
                  fullWidth
                  id="ttl"
                  value={dehiscence}
                  onChange={(event) => setDehiscence(event.target.value)}
                />
              </Grid>
            ) : (
              ""
            )}
            <Grid item xs={12}>
              <Grid container justifyContent="center" alignItems="center">
                <Button
                  variant="contained"
                  onClick={handleUpdate}
                  sx={{ mb: 2, mr: 1 }}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDelete}
                  sx={{ mb: 2 }}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default Header;
