import { Box, Paper, Grid, Button } from "@mui/material";

import { useState } from "react";
import Alert from "@mui/material/Alert";
import heic2any from "heic2any";
import { UserAuth } from "../context/AuthContext";

const Header = ({ data, pasien }) => {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { user } = UserAuth();

  const [file, setFile] = useState("");
  const [gambar, setGambar] = useState({ imageUrl: "" });

  const handleImageUpload = async (event) => {
    const originalFile = event.target.files[0];

    if (originalFile && originalFile.type === "image/heic") {
      try {
        const convertedImage = await heic2any({
          blob: originalFile,
          toType: "image/jpeg",
        });

        // Create a new File object with the converted image and same file name
        const convertedFile = new File([convertedImage], originalFile.name, {
          type: "image/jpeg",
          lastModified: Date.now(),
        });

        setFile(convertedFile);
        setGambar({ imageUrl: URL.createObjectURL(convertedImage) });
      } catch (error) {
        console.error("HEIC conversion error:", error);
      }
    } else {
      setFile(originalFile);
      const reader = new FileReader();

      reader.onloadend = () => {
        setGambar({ imageUrl: reader.result });
      };

      if (originalFile) {
        reader.readAsDataURL(originalFile);
      }
    }
  };

  const handleClick = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("progress", data.progress);
      formData.append("photoCategory", data.photoCategory);
      formData.append("idDoctor", "7ed91ec28dcb4f61ad6d4a2a6078cf15");
      formData.append("idPatient", pasien.userId);

      const response = await fetch(
        "https://ashokanew-bjh4uafqtq-uc.a.run.app/stage",
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
            Admin: "true",
          },
        }
      );
      console.log(response);

      if (response.ok) {
        setSuccess(true);

        setError(false);
      } else {
        throw new Error("Request failed");
      }
    } catch (error) {
      console.error(error);
      setSuccess(false);
      setError(true);
      // Handle the error
    }
  };
  return (
    <Box
      m="2px"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Paper sx={{ p: 1, width: "100%" }}>
        {success && (
          <Alert sx={{ mb: 1 }} severity="success">
            Upload Success!
          </Alert>
        )}
        {error && (
          <Alert sx={{ mb: 1 }} severity="error">
            Upload Gagal!
          </Alert>
        )}
        <Box sx={{ minHeight: "300px" }}>
          {gambar.imageUrl ? (
            <img
              src={gambar.imageUrl}
              alt="burger"
              width={"100%"}
              style={{ objectFit: "fill", height: "300px" }}
            />
          ) : (
            <label htmlFor="image-upload">
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageUpload}
              />
              <Button variant="contained" component="span">
                Upload File
              </Button>
            </label>
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="center" alignItems="center">
              <Button variant="contained" onClick={handleClick} sx={{ mb: 2 }}>
                Add
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default Header;
