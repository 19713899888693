import { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { useTheme } from "@mui/material";
import Header from "../../components/Header";
import Alert from "@mui/material/Alert";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { UserAuth } from "../../context/AuthContext";

export default function ChangePassword(props) {
    const theme = useTheme();
  
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState(""); // New state for confirming the password
  
    const [empty, setEmpty] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState(true); // State for tracking password matching
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
  
    const { user, changePassword } = UserAuth();
  
    const clearHandle = () => {
      setNewPassword("");
      setConfirmNewPassword(""); // Clear the confirm password field as well
    };
  
    const handleSubmit = () => {
      setPasswordsMatch(true)
      setSuccess(false)
  
      if (!newPassword || !confirmNewPassword) {
        setEmpty(true);
        setPasswordsMatch(true); // Reset the matching state
        return;
      }
  
      if (newPassword !== confirmNewPassword) {
        setPasswordsMatch(false); // Set the matching state to false if passwords don't match
        return;
      }
  
      setLoading(true);
  
      // Call the changePassword function with the new password
      changePassword(newPassword)
        .then(() => {
          setSuccess(true);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Password change failed:", error);
          setLoading(false);
        });
    };
  

    return (
        <Box m="20px">
          <Header title="Change Password" />
          <ThemeProvider theme={theme}>
            <Container component="main">
              <CssBaseline />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box component="form" noValidate sx={{ mt: 2, p: 1 }}>
                  {empty && (
                    <Alert sx={{ mb: 1 }} severity="warning">
                      Fields Tidak Lengkap
                    </Alert>
                  )}
                  {!passwordsMatch && (
                    <Alert sx={{ mb: 1 }} severity="error">
                      Passwords do not match
                    </Alert>
                  )}
                  {success && (
                    <Alert sx={{ mb: 1 }} severity="success">
                      Password Berhasil diubah!
                    </Alert>)
                  }
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="newPassword"
                        label="New Password"
                        type="password"
                        value={newPassword}
                        onChange={(event) => setNewPassword(event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="confirmNewPassword"
                        label="Confirm New Password"
                        type="password"
                        value={confirmNewPassword}
                        onChange={(event) => setConfirmNewPassword(event.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container justifyContent="center" alignItems="center">
                        <Button
                          variant="contained"
                          color="warning"
                          onClick={clearHandle}
                          sx={{ mt: 3, mb: 2, mr: 1 }}
                        >
                          Clear
                        </Button>
                        <Button
                          variant="contained"
                          onClick={handleSubmit}
                          sx={{ mt: 3, mb: 2 }}
                        >
                          Change Password
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </Box>
      );
}
