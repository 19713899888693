import { useState } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Box, IconButton, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import logo from "../../images/logo.png";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import Avatar from "@mui/material/Avatar";
import SendIcon from "@mui/icons-material/Send";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
const Item = ({ title, to, icon, selected, setSelected }) => {
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: "#ffffff",
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const navigate = useNavigate();
  return (
    <>
      {props.role && (
        <Box
          position="sticky"
          top="0"
          height="100vh"
          sx={{
            "& .pro-sidebar-inner": {
              background: `#00093A !important`,
            },
            "& .pro-icon-wrapper": {
              backgroundColor: "transparent !important",
            },
            "& .pro-inner-item": {
              padding: "5px 35px 5px 20px !important",
            },
            "& .pro-inner-item:hover": {
              color: "#868dfb !important",
            },
            "& .pro-menu-item.active": {
              color: "#6870fa !important",
            },
          }}
        >
          <ProSidebar collapsed={isCollapsed}>
            <Menu iconShape="square">
              <MenuItem
                onClick={() => setIsCollapsed(!isCollapsed)}
                icon={
                  isCollapsed ? <MenuOutlinedIcon color="#ffffff" /> : undefined
                }
                style={{
                  margin: "10px 0 20px 0",
                  color: "#ffffff",
                }}
              >
                {!isCollapsed && (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    ml="15px"
                  >
                    <Avatar alt="ashoka" src={logo} />
                    <Typography variant="h3" color="#ffffff">
                      ASHOKA
                    </Typography>
                    <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                      <MenuOutlinedIcon sx={{ color: "#ffffff" }} />
                    </IconButton>
                  </Box>
                )}
              </MenuItem>

              <Box paddingLeft={isCollapsed ? undefined : "10%"}>
                <MenuItem
                  onClick={() => navigate("/")}
                  icon={<HomeOutlinedIcon />}
                  selected={selected}
                  style={{
                    color: "#ffffff",
                  }}
                >
                  Dashboard
                </MenuItem>
                <SubMenu
                  icon={<PeopleOutlinedIcon />}
                  title={"Data"}
                  selected={selected}
                  style={{
                    color: "#ffffff",
                  }}
                >
                  <Item
                    title="Pasien"
                    to="/pasien"
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Orang Tua"
                    to="/orangtua"
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Dokter Spesialis"
                    to="/spesialis"
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Dokter Umum"
                    to="/umum"
                    selected={selected}
                    setSelected={setSelected}
                  />
                </SubMenu>

                
                <SubMenu
                  icon={<AdminPanelSettingsIcon />}
                  title={"Admin"}
                  selected={selected}
                 
                  style={{
                    color: "#ffffff",
                  }}
                >
                  <Item
                    title="Add Pasien"
                    to="/addpasien"
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Add User"
                    to="/adduser"
                    selected={selected}
                    setSelected={setSelected}
                  />
                  {props.role === "superadmin" ? (
                    <Item
                      title="Add Admin"
                      to="/addadmin"
                      selected={selected}
                      setSelected={setSelected}
                    />
                  ) : (
                    ""
                  )}
                  <Item
                    title="Verifikasi Dokter"
                    to="/verifikasi"
                    selected={selected}
                    setSelected={setSelected}
                  />
                </SubMenu>
                <SubMenu
                  icon={<SendIcon />}
                  title={"Reminder"}
                  selected={selected}
                 
                  style={{
                    color: "#ffffff",
                  }}
                >
                  <Item
                    title="Reminder WA"
                    to="/sendreminder"
                    selected={selected}
                    setSelected={setSelected}
                  />

                </SubMenu>

                <SubMenu
                  icon={<SendIcon />}
                  title={"Profile"}
                  selected={selected}
                 
                  style={{
                    color: "#ffffff",
                  }}
                >
                  <Item
                    title="Change Password"
                    to="/changepassword"
                    selected={selected}
                    setSelected={setSelected}
                  />

                </SubMenu>
              </Box>
            </Menu>
          </ProSidebar>
        </Box>
      )}
    </>
  );
};

export default Sidebar;
