import {
  createContext, useContext, useEffect, useState,
} from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updatePassword,
  getAuth
} from 'firebase/auth';
import { auth } from '../firebase';

const UserContext = createContext();

export function AuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  const [role, setRole] = useState('');
  const [isLoading, setLoading] = useState(true);

  const createUser = (email, password) => createUserWithEmailAndPassword(auth, email, password);

  const signIn = (email, password) => signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => userCredential.user.getIdTokenResult()
      .then((idTokenResult) => {
        if (idTokenResult.claims.role) {
          setRole(idTokenResult.claims.role);
          return userCredential;
        }
        signOut(auth);
        throw new Error('User does not have appropriate role.');
      }));

  const logout = () => signOut(auth);

  const changePassword = (newPassword) => updatePassword(auth.currentUser, newPassword)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
      if (currentUser) {
        currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            setRole(idTokenResult.claims.role || '');
          })
          .catch((error) => console.error(error));
      } else {
        setRole('');
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider value={{
      createUser, user, role, isLoading, logout, signIn, changePassword
    }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const UserAuth = () => useContext(UserContext);
