import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CarouselPaper from "../../components/CarouselPaper";
import heic2any from "heic2any";
import Header from "../../components/Header";
import { UserAuth } from "../../context/AuthContext";
import AccordionPasien from "../../components/AccordionPasien";
import Alert from "@mui/material/Alert";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Backdrop,
} from "@mui/material";

export default function Details(props) {
  const theme = useTheme();
  const params = useParams();
  const [expanded, setExpanded] = useState("");
  const { user } = UserAuth();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    fullName: "",
    placeOfBirth: "",
    dateOfBirth: "",
    patientType: "",
    parentPhoneNumber: "",
    weight: "",
    height: "",
    residence: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [data, setData] = useState({});
  const [treatmentLocation, setTreatmentLocation] = useState([]);
  const [progress, setProgress] = useState(0);
  const [uploadBool, setUploadBool] = useState(false);

  const [editDialog, setEditDialog] = useState(false);
  const [hapusDialog, sethapusDialog] = useState(false);
  const [detailDialog, setDetailDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loadedProgress, setLoadedProgress] = useState([]);
  const [category, setCategory] = useState(0);

  const matches = useMediaQuery("(min-width:750px)");

  const handleAccordionChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleImageUpload = async (event, tipe, angka) => {
    const originalFile = event.target.files[0];

    if (originalFile) {
      if (
        originalFile.type.startsWith("image/") ||
        originalFile.name.split(".").pop() === "heic"
      ) {
        if (originalFile.name.split(".").pop().toLowerCase() === "heic") {
          try {
            const convertedImage = await heic2any({
              blob: originalFile,
              toType: "image/jpeg",
            });

            const convertedFile = new File(
              [convertedImage],
              originalFile.name,
              {
                type: "image/jpeg",
                lastModified: Date.now(),
              }
            );

            navigate("/photoeditor", {
              state: {
                imageUrl: URL.createObjectURL(convertedImage),
                data: { photoCategory: tipe, progress: angka },
                pasien: data,
              },
            });
          } catch (error) {
            console.error("HEIC conversion error:", error);
          }
        } else {
          const reader = new FileReader();

          reader.onloadend = () => {
            navigate("/photoeditor", {
              state: {
                imageUrl: URL.createObjectURL(originalFile),
                data: { photoCategory: tipe, progress: angka },
                pasien: data,
              },
            });
          };

          reader.readAsDataURL(originalFile);
        }
      } else if (originalFile.type.startsWith("video/")) {
        const videoUrl = URL.createObjectURL(originalFile);
        try {
          const formData = new FormData();
          formData.append("file", originalFile);
          formData.append("progress", angka);
          formData.append("photoCategory", tipe);
          formData.append("idDoctor", "7ed91ec28dcb4f61ad6d4a2a6078cf15");
          formData.append("idPatient", data.userId);
          console.log(originalFile, angka, tipe, data.userId);
          const response = await fetch(
            "https://ashokanew-bjh4uafqtq-uc.a.run.app/stage",
            {
              method: "POST",
              body: formData,
              headers: {
                Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
                Admin: "true",
              },
            }
          );

          if (response.ok) {
            console.log(response);
          } else {
            throw new Error("Request failed");
          }
        } catch (error) {
          console.error(error);
        }

        console.log("Video URL:", videoUrl);
      } else {
        console.log("Unsupported file type:", originalFile.type);
        console.log(originalFile);
      }
    } else {
      console.log("No file selected.");
    }
  };

  const handleEditClick = () => {
    setEditDialog(true);
  };

  const handleHapusClick = () => {
    sethapusDialog(true);
  };

  const handleDialogClose = () => {
    setEditDialog(false);
  };

  const handleHapusDialog = () => {
    sethapusDialog(false);
  };

  const deleteHandler = () => {
    sethapusDialog(false);
    setLoading(true);
    const payload = {
      token: user.stsTokenManager.accessToken,
      userId: params.idPasien,
    };

    axios
      .delete(
        "https://ashokanew-bjh4uafqtq-uc.a.run.app/pasien/" + params.idPasien,
        {
          data: JSON.stringify(payload),
          headers: {
            Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
            Admin: "true",
          },
        }
      )
      .then((response) => {
        navigate("/pasien");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const editHandler = () => {
    setEditDialog(false);
    setLoading(true);
    const updatedData = {
      dateOfBirth: formData.dateOfBirth,
      fullName: formData.fullName,
      height: formData.height,
      placeOfBirth: formData.placeOfBirth,
      userId: params.idPasien,
      weight: formData.weight,
      patientType: formData.patientType,
      parentPhoneNumber: formData.parentPhoneNumber,
      residence: formData.residence,
      progress: progress,
    };

    console.log(updatedData);

    fetch("https://ashokanew-bjh4uafqtq-uc.a.run.app/pasien", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
        Admin: "true",
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => {
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
      });
  };

  const editProgressHandler = (progress) => {
    setLoading(true);
    const updatedData = {
      dateOfBirth: formData.dateOfBirth,
      fullName: formData.fullName,
      height: formData.height,
      placeOfBirth: formData.placeOfBirth,
      userId: params.idPasien,
      weight: formData.weight,
      patientType: formData.patientType,
      parentPhoneNumber: formData.parentPhoneNumber,
      residence: formData.residence,
      progress: progress,
    };

    fetch("https://ashokanew-bjh4uafqtq-uc.a.run.app/pasien", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
        Admin: "true",
      },
      body: JSON.stringify(updatedData),
    })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const downloadHandler = () => {
    setLoading(true);
    const payload = {
      idPasien: params.idPasien,
    };

    axios
      .post(
        "https://backend-ashoka-bjh4uafqtq-uc.a.run.app/pasien/generatepdf",
        JSON.stringify(payload),
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "result.pdf");

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleDetailDialog = () => {
    setDetailDialog(false);
    setUploadBool(false);
  };

  const progressOneHandler = (tipe, progress) => {
    setLoadedProgress([]);
    setCategory(0);
    const payload = {
      progress: parseInt(progress),
      photoCategory: parseInt(tipe),
      idPatient: params.idPasien,
    };
    console.log(payload);

    axios
      .post(
        "https://ashokanew-bjh4uafqtq-uc.a.run.app/stage/search",
        JSON.stringify(payload),
        {
          headers: {
            Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
            Admin: "true",
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        console.log(response);
        setCategory(tipe);
        setLoadedProgress(response.data.stage);
        setDetailDialog(true);
      })
      .catch((error) => {
        setDetailDialog(true);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://ashokanew-bjh4uafqtq-uc.a.run.app/pasien/${params.idPasien}`,
          {
            headers: {
              Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
              Admin: "true",
            },
          }
        );
        console.log(response);
        const datanya = response.data;

        if (!datanya.error && datanya.pasien) {
          setData(datanya.pasien);
          setFormData({
            fullName: datanya.pasien.fullName,
            placeOfBirth: datanya.pasien.placeOfBirth,
            dateOfBirth: datanya.pasien.dateOfBirth,
            patientType: datanya.pasien.patientType,
            parentPhoneNumber: datanya.pasien.parentPhoneNumber,
            weight: datanya.pasien.weight,
            height: datanya.pasien.height,
            residence: datanya.pasien.residence,
          });
          setProgress(datanya.pasien.progress);
          setTreatmentLocation(datanya.pasien.treatmentLocation[0]);
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, [params.idPasien]);

  if (!data || Object.keys(data).length === 0) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <>
      <Box m="15px">
        <Header title="Detail Data Pasien" />

        {data && (
          <ThemeProvider theme={theme}>
            <Container component="main">
              <CssBaseline />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {success && (
                  <Alert sx={{ mb: 1 }} severity="success">
                    Berhasil Mengubah Pasien!
                  </Alert>
                )}
                <Box component="form" noValidate sx={{ mt: 2, p: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item sm={6}>
                      <TextField
                        size="small"
                        autoComplete="fullName"
                        name="fullName"
                        required
                        fullWidth
                        id="fullName"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Nama Lengkap:
                            </InputAdornment>
                          ),
                        }}
                        value={formData.fullName}
                        onChange={handleChange}
                        sx={{ input: { textAlign: "end" } }}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <Box display="flex" justifyContent="end" alignItems="end">
                        {/* <Button
                          onClick={downloadHandler}
                          variant="contained"
                          sx={{ mr: 1 }}
                        >
                          Download
                        </Button> */}
                        <Button
                          onClick={handleEditClick}
                          variant="contained"
                          sx={{ mr: 1 }}
                        >
                          Edit
                        </Button>
                        {props.role === "superadmin" && (
                          <Button
                            onClick={handleHapusClick}
                            variant="contained"
                            color="error"
                          >
                            Delete
                          </Button>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        size="small"
                        autoComplete="ttl"
                        name="placeOfBirth"
                        required
                        fullWidth
                        id="ttl"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Tempat, Tanggal Lahir:
                            </InputAdornment>
                          ),
                        }}
                        value={
                          formData.placeOfBirth + ", " + formData.dateOfBirth
                        }
                        onChange={handleChange}
                        sx={{ input: { textAlign: "end" } }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <FormControl fullWidth size="small">
                        <InputLabel id="tipeUser">Tipe User</InputLabel>
                        <Select
                          labelId="tipeUser"
                          id="demo-simple-select"
                          value={formData.patientType}
                          label="Tipe User"
                          onChange={handleChange}
                          name="patientType"
                        >
                          <MenuItem value={"Hipospadia"}>Hipospadia</MenuItem>
                          <MenuItem value={"Sirkumsisi"}>Sirkumsisi</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    {data.treatmentLocation && (
                      <Grid item xs={12} sm={3}>
                        <FormControl fullWidth size="small">
                          <InputLabel id="plate-1">
                            Treatment Location
                          </InputLabel>
                          <Select
                            labelId="urethal"
                            id="urethal-select"
                            label="Urethal Plate"
                            value={treatmentLocation}
                            onChange={(event) =>
                              setTreatmentLocation(event.target.value)
                            }
                          >
                            {data.treatmentLocation.map((key) => (
                              <MenuItem value={key} key={key}>
                                {key}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        size="small"
                        autoComplete="Kontak"
                        name="parentPhoneNumber"
                        required
                        fullWidth
                        id="Kontak"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              Kontak:
                            </InputAdornment>
                          ),
                        }}
                        value={formData.parentPhoneNumber}
                        onChange={handleChange}
                        sx={{ input: { textAlign: "end" } }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        size="small"
                        autoComplete="bb"
                        name="weight"
                        required
                        fullWidth
                        id="bb"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              BB:
                            </InputAdornment>
                          ),
                        }}
                        value={formData.weight}
                        onChange={handleChange}
                        sx={{ input: { textAlign: "end" } }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3} sx={{ mb: 2 }}>
                      <TextField
                        size="small"
                        autoComplete="tb"
                        name="height"
                        required
                        fullWidth
                        id="tb"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              TB:
                            </InputAdornment>
                          ),
                        }}
                        value={formData.height}
                        onChange={handleChange}
                        sx={{ input: { textAlign: "end" } }}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <AccordionPasien
                        data={data}
                        title="Pre-Operasi"
                        expanded={expanded === "panel1"}
                        onChange={handleAccordionChange("panel1")}
                        items={1}
                        progress={progress}
                        matches={matches}
                        progressOneHandler={progressOneHandler}
                        handleImageUpload={handleImageUpload}
                      />
                    </Grid>
                    <Grid item sm={progress < 2 ? 11 : 12}>
                      <AccordionPasien
                        data={data}
                        title="Intra-Operasi"
                        expanded={expanded === "panel2"}
                        onChange={handleAccordionChange("panel2")}
                        items={2}
                        progress={progress}
                        matches={matches}
                        progressOneHandler={progressOneHandler}
                        handleImageUpload={handleImageUpload}
                      />
                    </Grid>
                    {progress < 2 ? (
                      <Grid item sm={1}>
                        {" "}
                        <Button
                          variant="contained"
                          onClick={() => editProgressHandler(2)}
                        >
                          Unlock
                        </Button>
                      </Grid>
                    ) : (
                      ""
                    )}

                    <Grid item sm={progress < 3 ? 11 : 12}>
                      <AccordionPasien
                        data={data}
                        title="Pasca-Operasi"
                        expanded={expanded === "panel3"}
                        onChange={handleAccordionChange("panel3")}
                        items={3}
                        progress={progress}
                        matches={matches}
                        progressOneHandler={progressOneHandler}
                        handleImageUpload={handleImageUpload}
                      />
                    </Grid>
                    {progress < 3 ? (
                      <Grid item sm={1}>
                        {" "}
                        <Button
                          variant="contained"
                          onClick={() => editProgressHandler(3)}
                        >
                          Unlock
                        </Button>
                      </Grid>
                    ) : (
                      ""
                    )}
                    <Grid item sm={progress < 4 ? 11 : 12}>
                      <AccordionPasien
                        data={data}
                        title="Follow Up (0-3 bulan)"
                        expanded={expanded === "panel4"}
                        onChange={handleAccordionChange("panel4")}
                        items={4}
                        progress={progress}
                        matches={matches}
                        progressOneHandler={progressOneHandler}
                        handleImageUpload={handleImageUpload}
                      />
                    </Grid>
                    {progress < 4 ? (
                      <Grid item sm={1}>
                        {" "}
                        <Button
                          variant="contained"
                          onClick={() => editProgressHandler(4)}
                        >
                          Unlock
                        </Button>
                      </Grid>
                    ) : (
                      ""
                    )}
                    <Grid item sm={progress < 5 ? 11 : 12}>
                      <AccordionPasien
                        data={data}
                        title="Follow Up (3-12 bulan)"
                        expanded={expanded === "panel5"}
                        onChange={handleAccordionChange("panel5")}
                        items={5}
                        progress={progress}
                        matches={matches}
                        progressOneHandler={progressOneHandler}
                        handleImageUpload={handleImageUpload}
                      />
                    </Grid>
                    {progress < 5 ? (
                      <Grid item sm={1}>
                        {" "}
                        <Button
                          variant="contained"
                          onClick={() => editProgressHandler(5)}
                        >
                          Unlock
                        </Button>
                      </Grid>
                    ) : (
                      ""
                    )}
                    <Grid item sm={progress < 6 ? 11 : 12}>
                      <AccordionPasien
                        data={data}
                        title="Follow Up (>12 bulan)"
                        expanded={expanded === "panel6"}
                        onChange={handleAccordionChange("panel6")}
                        items={6}
                        progress={progress}
                        matches={matches}
                        progressOneHandler={progressOneHandler}
                        handleImageUpload={handleImageUpload}
                      />
                    </Grid>
                    {progress < 6 ? (
                      <Grid item sm={1}>
                        {" "}
                        <Button
                          variant="contained"
                          onClick={() => editProgressHandler(6)}
                        >
                          Unlock
                        </Button>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Box>
              </Box>
            </Container>
          </ThemeProvider>
        )}
        <Dialog
          open={editDialog}
          onClose={handleDialogClose}
          aria-labelledby="verification-dialog-title"
          aria-describedby="verification-dialog-description"
        >
          <DialogTitle id="verification-dialog-title">
            Konfirmasi Edit
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="body1"
              color="text.primary"
              id="verification-dialog-description"
            >
              Apakah Anda yakin ingin mengedit data ini?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Batal
            </Button>
            <Button onClick={editHandler} variant="contained" color="primary">
              Edit
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={hapusDialog}
          onClose={handleHapusDialog}
          aria-labelledby="verification-dialog-title"
          aria-describedby="verification-dialog-description"
        >
          <DialogTitle id="verification-dialog-title">
            Konfirmasi Hapus
          </DialogTitle>
          <DialogContent>
            <Typography
              variant="body1"
              color="text.primary"
              id="verification-dialog-description"
            >
              Apakah Anda yakin ingin menghapus data ini?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleHapusDialog} color="primary">
              Batal
            </Button>
            <Button onClick={deleteHandler} variant="contained" color="error">
              Hapus
            </Button>
          </DialogActions>
        </Dialog>

        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>

      <Dialog
        fullWidth
        scroll={"paper"}
        open={detailDialog}
        fullScreen={fullScreen}
        onClose={handleDetailDialog}
        aria-labelledby="verification-dialog-title"
        aria-describedby="verification-dialog-description"
        maxWidth={loadedProgress.length > 0 ? "xl" : "sm"}
        PaperProps={
          loadedProgress.length > 0
            ? {
                style: {
                  height: "100%",
                },
              }
            : {}
        }
      >
        <DialogContent>
          {loadedProgress.length > 0 ? (
            <CarouselPaper
              data={loadedProgress}
              category={category}
              pasien={data}
              upload={uploadBool}
            />
          ) : (
            <Alert severity="error">No Data Found</Alert>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
}
