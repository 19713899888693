import {
  TextField,
  Typography,
  Box,
  Paper,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Button,
} from "@mui/material";
import { useState, useEffect } from "react";
import Alert from "@mui/material/Alert";
import { UserAuth } from "../context/AuthContext";
import busur from "../images/busur.png";
import busurwarna from "../images/busurwarna.png";
import { PinturaEditor } from "@pqina/react-pintura";
import { getEditorDefaults } from "@pqina/pintura";
const Header = ({ data, pasien }) => {
  const { user } = UserAuth();
  const [glans, setGlans] = useState(data.glansQuality || "");
  const [valid, setValid] = useState(data.validity !== undefined ? data.validity : "");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [dokter, setDokter] = useState({});
  const [teknikOperasi, setTeknikOperasi] = useState(data.operationTechnique || "");
  const [fistel, setFistel] = useState(data.fistel || "");
  const [dehiscence, setDehiscence] = useState(data.dehiscence || "");

  const fistelMap = {
    Ya: 1,
    Tidak: 0,
  };

  const teknikOperasiMap = {
    "Tubularized Incised Plate": 1,
    "Dorsal Inlay Graft": 2,
    "Dorsal Onlay Graft": 3,
    "Mathieu Urethroplasty": 4,
    "Two-Staged Urethroplasty": 5,
    "Re-do Urethroplasty": 6,
    "EPA Urethroplasty": 7,
    Lainnya: 8,
  };

  const kualitasGlansHipos = {
    "Groove baik": 1,
    "Groove kurang": 2,
    "Groove rata": 3,
  };

  const kualitasGlansMap = {
    "Ukuran baik, beralur dalam": 1,
    "Ukuran cukup, beralur": 2,
    "Ukuran kecil, fibrosis atau rata": 3,
    "Ukuran sangat kecil, sangat sempit atau rata": 4,
  };

  const handleDelete = () => {
    console.log(data.id)
    fetch("https://ashokanew-bjh4uafqtq-uc.a.run.app/stage/"+data.id, {
      method: "DELETE",
      headers: {
        "Authorization": `Bearer ${user.stsTokenManager.accessToken}`,
        "Admin": "true",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setSuccess(true);
        setError(false);
      })
      .catch((error) => {
        setError(true);
        setSuccess(false);
      });
  };

  const validityMap = {
    Valid: 1,
    "Tidak Valid": 0,
  };

  const handleClick = async () => {
    const updatedData = {
      glansQuality: glans,
      validity: valid,
      id: data.id,
      idDoctor: "7ed91ec28dcb4f61ad6d4a2a6078cf15"
    };
    
    if (teknikOperasi !== "") {
      updatedData.operationTechnique = teknikOperasi;
    }

    if (fistel !== "") {
      updatedData.fistel = fistel;
    }

    if (dehiscence !== "") {
      updatedData.dehiscence = dehiscence;
    }

    console.log(updatedData);
 try {
      const response = await fetch(
        "https://ashokanew-bjh4uafqtq-uc.a.run.app/stage",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user.stsTokenManager.accessToken}`,
            Admin: "true",
          },
          body: JSON.stringify(updatedData),
        }
      );
      const result = await response.json();
      setSuccess(true)
      setError(false)
    } catch (error) {
      setSuccess(false)
      setError(true)
    }
  };

  

  return (
    <>
      <Box
        m="10px"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Paper sx={{ p: 1, width: "100%" }}>
          {success && (
            <Alert sx={{ mb: 1 }} severity="success">
              Update Success!
            </Alert>
          )}
          {error && (
            <Alert sx={{ mb: 1 }} severity="error">
              Update Gagal!
            </Alert>
          )}

          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{
                mt: 2,
                height: 600,
              }}
            >
              <PinturaEditor
                {...getEditorDefaults()}
                src={data.fileUrl}
                enableButtonExport={false}
                util={"sticker"}
                enableUtils={false}
                enableToolbar={false}
                stickers={[busur, busurwarna]}
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth sx={{ mt: 1 }}>
                <InputLabel id="validity-1">Validity</InputLabel>
                <Select
                  labelId="validity"
                  id="valid-select"
                  label="Validity"
                  value={valid}
                  onChange={(event) => setValid(event.target.value)}
                >
                  <MenuItem name={`validity1`} value={true} key={`validity1`}>
                      true
                    </MenuItem>
                    <MenuItem name={`validity2`} value={false} key={`validity2`}>
                      false
                    </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="glans-1">Kualitas Glans</InputLabel>
                <Select
                  labelId="glans"
                  id="glans-select"
                  label="Kualitas Glans"
                  value={glans}
                  onChange={(event) => setGlans(event.target.value)}
                >
                  {Object.keys(
                    pasien.patientType === "Hipospadia"
                      ? kualitasGlansHipos
                      : kualitasGlansMap
                  ).map((key) => (
                    <MenuItem
                      value={key}
                      key={
                        pasien.patientType === "Hipospadia"
                          ? kualitasGlansHipos[key]
                          : kualitasGlansMap[key]
                      }
                    >
                      {key}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {data.progress === 2 ? (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="teknikOperasi-1">Teknik Operasi</InputLabel>
                  <Select
                    labelId="teknikOperasi"
                    id={"bentuk-select" + data.imageUrl}
                    label="Teknik Operasi"
                    value={teknikOperasi}
                    onChange={(event) => setTeknikOperasi(event.target.value)}
                    name="teknikOperasi"
                  >
                    {Object.keys(teknikOperasiMap).map((key) => (
                      <MenuItem
                        name={key}
                        value={key}
                        key={teknikOperasiMap[key]}
                      >
                        {key}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ) : (
              ""
            )}

            {data.progress >= 4 ? (
              <Grid item xs={12}>
                <TextField
                  label="Fistel"
                  name="fistel"
                  fullWidth
                  id="fistel"
                  value={fistel}
                  onChange={(event) => setFistel(event.target.value)}
                />
              </Grid>
            ) : (
              ""
            )}

            {data.progress >= 4 ? (
              <Grid item xs={12}>
                <TextField
                  label="Wound Dehiscence"
                  name="placeOfBirth"
                  fullWidth
                  id="ttl"
                  value={dehiscence}
                  onChange={(event) => setDehiscence(event.target.value)}
                />
              </Grid>
            ) : (
              ""
            )}

            

            <Grid item xs={12}>
              <Grid container justifyContent="center" alignItems="center">
                <Button
                  onClick={handleClick}
                  variant="contained"
                  sx={{ mb: 2, mr: 1 }}
                >
                  Edit
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleDelete}
                  sx={{ mb: 2 }}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    </>
  );
};

export default Header;
